export default {
  namespaced: true,

  getters: {
    all: (state, getters, rootState) => {
      return rootState.factory.allContent?.tanks || []
    },
    forUser: (state, getters, rootState) => {
      return rootState.factory.content?.tanks || []
    },
    allByIds: (state, getters) => {
      return getters.all.reduce((acc, tank) => {
        acc[tank.id] = tank
        return acc
      }, {})
    },
    byIds: (state, getters) => {
      return getters.forUser.reduce((acc, tank) => {
        acc[tank.id] = tank
        return acc
      }, {})
    },
    bySiteIds: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/sites/forUser'].reduce((acc, site) => {
        acc[site.id] = Object.freeze(
          site.tankIds.map(id => getters.byIds[id]).filter(Boolean)
        )
        return acc
      }, {})
    },
    byVirtualSiteIds: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/virtualSites/forUser'].reduce((acc, site) => {
        acc[site.id] = Object.freeze(
          site.tankIds.map(id => getters.byIds[id]).filter(Boolean)
        )
        return acc
      }, {})
    },
    names: (state, getters) => {
      return getters.all.reduce((names, tank) => {
        names[tank.id] = tank.name
        return names
      }, {})
    },
    colors: (state, getters) => {
      return getters.all.reduce((colors, tank) => {
        colors[tank.id] = tank.color
        return colors
      }, {})
    },
    locations: (state, getters, rootState, rootGetters) => {
      return getters.all.reduce((locations, tank) => {
        let location = tank.name
        const site = rootGetters['factory/sites/allByIds'][tank.siteId]
        if (site) {
          location = `${site.name} / ${tank.name}`
        }
        locations[tank.id] = location
        return locations
      }, {})
    },
    volumes: (state, getters) => {
      return getters.all.reduce((volumes, tank) => {
        let volume = 0
        const { width, height, length } = tank.size
        if (width && height && length) {
          volume = width * height * length
        }
        volumes[tank.id] = volume
        return volumes
      }, {})
    }
  }
}
