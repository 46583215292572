import { getField, updateField } from 'vuex-map-fields'

function makeTankForm (data) {
  const form = {
    id: null,
    originSiteId: null,
    siteId: null,
    virtualSiteIds: [],
    originPositionNumber: undefined,
    positionNumber: undefined,
    color: '',
    name: ''
  }
  if (data) {
    form.id = data.id
    form.originSiteId = data.siteId
    form.siteId = data.siteId
    form.virtualSiteIds = data.virtualSiteIds
    form.positionNumber = data.positionNumber
    form.originPositionNumber = data.positionNumber
    form.color = data.color
    form.name = data.name
  }
  return form
}

function makeSiteForm (data) {
  const form = {
    id: null,
    name: '',
    color: '#616161',
    tankIds: []
  }
  if (data) {
    form.id = data.id
    form.name = data.name
    form.color = data.color
    form.tankIds = data.tankIds
  }
  return form
}

function makeVirtualSiteForm (data) {
  const form = {
    id: null,
    name: '',
    color: '#616161',
    tankIds: [],
    tanksTimeLimits: []
  }
  if (data) {
    form.id = data.id
    form.name = data.name
    form.color = data.color
    form.tankIds = data.tankIds
    form.tanksTimeLimits = data.tanksTimeLimits
  }
  return form
}

export default {
  namespaced: true,

  state: {
    tankForm: makeTankForm(),
    siteForm: makeSiteForm(),
    virtualSiteForm: makeVirtualSiteForm()
  },

  getters: {
    getField
  },

  mutations: {
    updateField,

    RESET_TANK_FORM: state => {
      state.tankForm = makeTankForm()
    },
    SET_TANK_FORM: (state, data) => {
      state.tankForm = makeTankForm(data)
    },

    RESET_SITE_FORM: state => {
      state.siteForm = makeSiteForm()
    },
    SET_SITE_FORM: (state, data) => {
      state.siteForm = makeSiteForm(data)
    },

    RESET_VIRTUAL_SITE_FORM: state => {
      state.virtualSiteForm = makeVirtualSiteForm()
    },
    SET_VIRTUAL_SITE_FORM: (state, data) => {
      state.virtualSiteForm = makeVirtualSiteForm(data)
    }
  }
}
