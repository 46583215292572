import Layout from '@/modules/core/components/Layout'
import Login from '@/modules/core/pages/Login'

export const makeRoutes = ({ acl, i18n }) => {
  return [
    {
      path: '/',
      redirect: '/factory'
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        scope: 'auth',
        getTitle: () => i18n.t('core.router.login')
      },
      component: Login
    },
    {
      path: '/error',
      component: Layout,
      meta: {
        module: 'core'
      },
      children: [
        {
          path: '',
          name: 'error',
          component: () => import('@/modules/core/pages/AppError'),
          meta: {
            scope: 'error',
            module: 'core',
            getTitle: () => i18n.t('core.router.error')
          }
        },
        {
          path: '404',
          name: 'error404',
          component: () => import('@/modules/core/pages/AppError'),
          props: {
            description: 'Страница не найдена...'
          },
          meta: {
            scope: 'error',
            module: 'core',
            getTitle: () => i18n.t('core.router.errorWithNumber', { number: 404 })
          }
        },
        {
          path: '403',
          name: 'error403',
          component: () => import('@/modules/core/pages/AppError'),
          props: {
            description: 'Недостаточно прав доступа...'
          },
          meta: {
            scope: 'error',
            module: 'core',
            getTitle: () => i18n.t('core.router.errorWithNumber', { number: 403 })
          }
        },
        {
          path: '401',
          name: 'error401',
          component: () => import('@/modules/core/pages/AppError'),
          props: {
            description: 'Необходимо авторизоваться...'
          },
          meta: {
            scope: 'error',
            module: 'core',
            getTitle: () => i18n.t('core.router.errorWithNumber', { number: 401 })
          }
        }
      ]
    },
    {
      path: '/factory',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.factory'),
        icon: 'gamepad-circle-right'
      },
      children: [
        {
          path: '',
          name: 'factory',
          component: () => import('@/modules/core/pages/Factory'),
          meta: {
            scope: 'factory',
            module: 'core',
            getTitle: () => i18n.t('core.router.factory'),
            icon: 'gamepad-circle-right',
            order: 1,
            navbar: true
          }
        },
        {
          path: 'site/:siteId',
          component: () => import('@/modules/core/components/SiteWrapper.vue'),
          meta: {
            module: 'core'
          },
          children: [
            {
              path: 'fish',
              component: () => import('@/modules/core/pages/SiteFish.vue'),
              name: 'factory.site.fish',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.siteFish'),
                icon: 'table'
              }
            },
            {
              path: 'fish/catch',
              component: () => import('@/modules/core/pages/SiteFishCatch.vue'),
              name: 'factory.site.catch',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.siteFishCatch'),
                icon: 'table'
              }
            },
            {
              path: 'feeding',
              component: () => import('@/modules/core/pages/SiteFeeding.vue'),
              name: 'factory.site.feeding',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.siteFeeding'),
                icon: 'table'
              }
            },
            {
              path: 'mortality',
              component: () => import('@/modules/core/pages/SiteFishMortality.vue'),
              name: 'factory.site.mortality',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.siteFishMortality'),
                icon: 'table'
              }
            }
          ]
        },
        {
          path: 'virtual-site/:virtualSiteId',
          component: () => import('@/modules/core/components/VirtualSiteWrapper.vue'),
          meta: {
            module: 'core'
          },
          children: [
            {
              path: 'feeding',
              component: () => import('@/modules/core/pages/VirtualSiteFeeding.vue'),
              name: 'factory.virtualSite.feeding',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.virtualSiteFeeding'),
                icon: 'table'
              }
            },
            {
              path: 'mortality',
              component: () => import('@/modules/core/pages/VirtualSiteFishMortality.vue'),
              name: 'factory.virtualSite.mortality',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.virtualSiteFishMortality'),
                icon: 'table'
              }
            },
            {
              path: 'fish',
              component: () => import('@/modules/core/pages/VirtualSiteFish.vue'),
              name: 'factory.virtualSite.fish',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.virtualSiteFish'),
                icon: 'table'
              }
            },
            {
              path: 'fish/catch',
              component: () => import('@/modules/core/pages/VirtualSiteFishCatch.vue'),
              name: 'factory.virtualSite.catch',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.virtualSiteFishCatch'),
                icon: 'table'
              }
            }
          ]
        },
        {
          path: 'tank/:tankId',
          component: () => import('@/modules/core/components/TankWrapper.vue'),
          meta: {
            module: 'core'
          },
          children: [
            {
              path: 'fish',
              component: () => import('@/modules/core/pages/TankFish.vue'),
              name: 'factory.tank.fish',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.tankFish'),
                icon: 'table'
              }
            },
            {
              path: 'density',
              component: () => import('@/modules/core/pages/TankFishDensity.vue'),
              name: 'factory.tank.density',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.tankFishDensity'),
                icon: 'table'
              }
            },
            {
              path: 'water',
              component: () => import('@/modules/core/pages/TankWater.vue'),
              name: 'factory.tank.water',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.tankWater'),
                icon: 'table'
              }
            },
            {
              path: 'mortality',
              component: () => import('@/modules/core/pages/TankFishMortality.vue'),
              name: 'factory.tank.mortality',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.tankFishMortality'),
                icon: 'table'
              }
            },
            {
              path: 'catch',
              component: () => import('@/modules/core/pages/TankFishCatch.vue'),
              name: 'factory.tank.catch',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.tankFishCatch'),
                icon: 'table'
              }
            },
            {
              path: 'seeding',
              component: () => import('@/modules/core/pages/TankFishSeeding.vue'),
              name: 'factory.tank.seeding',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.tankFishSeeding'),
                icon: 'table'
              }
            },
            {
              path: 'feeding',
              component: () => import('@/modules/core/pages/TankFeeding.vue'),
              name: 'factory.tank.feeding',
              meta: {
                scope: 'factory',
                module: 'core',
                getTitle: () => i18n.t('core.router.tankFeeding'),
                icon: 'table'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/data-input',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.dataInput'),
        icon: 'import'
      },
      children: [
        {
          path: '',
          name: 'dataInput',
          component: () => import('@/modules/core/pages/DataInput'),
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title'),
            icon: 'import',
            order: 3,
            navbar: true
          }
        },
        {
          path: 'batch',
          name: 'dataInput.batch',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'batch'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'water',
          name: 'dataInput.water',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'water'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'feeding',
          name: 'dataInput.feeding',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'feeding'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'moving',
          name: 'dataInput.moving',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'moving'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'catch',
          name: 'dataInput.catch',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'catch'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'mortality',
          name: 'dataInput.mortality',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'mortality'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'seeding',
          name: 'dataInput.seeding',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'seeding'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'fishWeight',
          name: 'dataInput.fishWeight',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'fishWeight'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'feedRation',
          name: 'dataInput.feedRation',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'feedRation'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: 'resetTanks',
          name: 'dataInput.resetTanks',
          component: () => import('@/modules/core/pages/DataInputForm'),
          props: {
            form: 'resetTanks'
          },
          meta: {
            scope: 'dataInput',
            module: 'core',
            getTitle: () => i18n.t('core.dataInput.title')
          }
        },
        {
          path: '*',
          redirect: '/data-input'
        }
      ]
    },
    {
      path: '/map',
      component: Layout,
      redirect: '/map',
      children: [
        {
          path: '',
          name: 'map.factory',
          component: () => import('@/modules/core/pages/FactoryMap'),
          meta: {
            scope: 'map',
            module: 'core',
            getTitle: () => i18n.t('core.router.factoryMap'),
            order: 2,
            navbar: true,
            icon: 'map'
          },
          children: [
            {
              path: 'tank/:tankId',
              name: 'map.tank',
              component: () => import('@/modules/core/pages/Tank'),
              meta: {
                scope: 'map',
                module: 'core',
                getTitle: () => i18n.t('core.router.factoryMap'),
                icon: 'gamepad-circle-right'
              }
            },
            {
              path: 'site/:siteId',
              name: 'map.site',
              component: () => import('@/modules/core/pages/Site'),
              meta: {
                scope: 'map',
                module: 'core',
                getTitle: () => i18n.t('core.router.factoryMap'),
                icon: 'gamepad-circle-right'
              }
            },
            {
              path: 'virtualSite/:virtualSiteId',
              name: 'map.virtualSite',
              component: () => import('@/modules/core/pages/VirtualSite'),
              meta: {
                scope: 'map',
                module: 'core',
                getTitle: () => i18n.t('core.router.site'),
                icon: 'gamepad-circle-right'
              }
            }
          ]
        },
        {
          path: 'editor',
          name: 'map.editor',
          component: () => import('@/modules/core/pages/FactoryEditor'),
          meta: {
            scope: 'map',
            module: 'core',
            getTitle: () => i18n.t('core.router.factoryMap'),
            icon: 'gamepad-circle-right',
            aclChecker: () => acl.isAllowed(acl.P.X, acl.R.Factory)
          }
        }
      ]
    },
    {
      path: '/settings',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.settings'),
        icon: 'settings'
      },
      children: [
        {
          path: 'factories',
          name: 'factoriesEditor',
          component: () => import('@/modules/core/pages/FactoriesEditor'),
          meta: {
            scope: 'administration',
            module: 'core',
            getTitle: () => i18n.t('entity.factories'),
            icon: 'settings',
            aclChecker: () => acl.isAllowed(acl.P.X, acl.R.Multifactories)
          }
        }
      ]
    },
    {
      path: '/summary',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.summary'),
        icon: 'chart-box',
        order: 7,
        navbar: true,
        aclChecker: () => acl.isAllowed(acl.P.R, acl.R.Summary)
      },
      children: [
        {
          path: '',
          redirect: '/summary/feeding'
        },
        {
          path: 'feeding',
          name: 'summary.feeding',
          component: () => import('@/modules/core/pages/FactorySummary'),
          props: {
            tab: 'feeding'
          },
          meta: {
            scope: 'summary',
            module: 'core',
            getTitle: () => i18n.t('core.router.summary')
          }
        },
        {
          path: 'feedingGrowth',
          name: 'summary.feedingGrowth',
          component: () => import('@/modules/core/pages/FactorySummary'),
          props: {
            tab: 'feedingGrowth'
          },
          meta: {
            scope: 'summary',
            module: 'core',
            getTitle: () => i18n.t('core.router.summary')
          }
        },
        {
          path: 'medicines',
          name: 'summary.medicines',
          component: () => import('@/modules/core/pages/FactorySummary'),
          props: {
            tab: 'medicines'
          },
          meta: {
            scope: 'summary',
            module: 'core',
            getTitle: () => i18n.t('core.router.summary')
          }
        },
        {
          path: 'mortality',
          name: 'summary.mortality',
          component: () => import('@/modules/core/pages/FactorySummary'),
          props: {
            tab: 'mortality'
          },
          meta: {
            scope: 'summary',
            module: 'core',
            getTitle: () => i18n.t('core.router.summary')
          }
        },
        {
          path: 'catch',
          name: 'summary.catch',
          component: () => import('@/modules/core/pages/FactorySummary'),
          props: {
            tab: 'catch'
          },
          meta: {
            scope: 'summary',
            module: 'core',
            getTitle: () => i18n.t('core.router.summary')
          }
        }
      ]
    },
    {
      path: '/notifications',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.notifications'),
        icon: 'bell'
      },
      children: [
        {
          path: '',
          name: 'notifications',
          component: () => import('@/modules/core/pages/Notifications'),
          meta: {
            scope: 'notifications',
            module: 'core',
            getTitle: () => i18n.t('core.router.notifications'),
            icon: 'bell'
          }
        }
      ]
    },
    {
      path: '/events',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.events'),
        icon: 'calendar-text'
      },
      children: [
        {
          path: '',
          name: 'events',
          component: () => import('@/modules/core/pages/Events'),
          meta: {
            scope: 'events',
            module: 'core',
            getTitle: () => i18n.t('core.router.events'),
            icon: 'calendar-text',
            order: 6,
            navbar: true,
            aclChecker: () => acl.isAllowed(acl.P.R, acl.R.Events)
          }
        }
      ]
    },
    {
      path: '/reports',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.reports'),
        icon: 'file-table'
      },
      children: [
        {
          path: '',
          name: 'reports',
          component: () => import('@/modules/core/pages/Reports'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.router.reports'),
            icon: 'file-table',
            order: 8,
            navbar: true,
            aclChecker: () => acl.isAllowedPattern(
              acl.P.R,
              new RegExp(`^${acl.R.Reports}.*$`)
            )
          }
        },
        {
          path: 'full',
          name: 'reports.full',
          component: () => import('@/modules/core/pages/ReportsFull'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.reports.factoryReport.title'),
            icon: 'gamepad-circle-right',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Reports, acl.R.FactoryReport)
            )
          }
        },
        {
          path: 'brief',
          name: 'reports.brief',
          component: () => import('@/modules/core/pages/ReportsBrief'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.reports.factoryBriefReport.title'),
            icon: 'gamepad-circle-right',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Reports, acl.R.FactoryBriefReport)
            )
          }
        },
        {
          path: 'fish-sale',
          name: 'reports.fishSale',
          component: () => import('@/modules/core/pages/ReportsFishSale'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.reports.fishSaleReport.title'),
            icon: 'cash-multiple',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Reports, acl.R.FishSaleReport)
            )
          }
        },
        {
          path: 'fish-moving',
          name: 'reports.fishMoving',
          component: () => import('@/modules/core/pages/ReportsFishMoving'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.reports.fishMovingReport.title'),
            icon: 'swap-horizontal',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Reports, acl.R.FishMovingReport)
            )
          }
        },
        {
          path: 'feeding',
          name: 'reports.feeding',
          component: () => import('@/modules/core/pages/ReportsFeeding'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.reports.feedingReport.title'),
            icon: 'dots-triangle',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Reports, acl.R.FeedingReport)
            )
          }
        },
        {
          path: 'stocks-state',
          name: 'reports.stocksState',
          component: () => import('@/modules/core/pages/ReportsStocksState'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.reports.stocksStateReport.title'),
            icon: 'garage',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Reports, acl.R.StocksStateReport)
            )
          }
        },
        {
          path: 'stock-feeds',
          name: 'reports.stockFeeds',
          component: () => import('@/modules/core/pages/ReportsStockFeeds'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.reports.stockFeedsReport.title'),
            icon: 'dots-triangle',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Reports, acl.R.StockFeedsReport)
            )
          }
        },
        {
          path: 'stock-medicines',
          name: 'reports.stockMedicines',
          component: () => import('@/modules/core/pages/ReportsStockMedicines'),
          meta: {
            scope: 'reports',
            module: 'core',
            getTitle: () => i18n.t('core.reports.stockMedicinesReport.title'),
            icon: 'hospital-box-outline',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Reports, acl.R.StockMedicinesReport)
            )
          }
        }
      ]
    },
    {
      path: '/registries',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.registries'),
        icon: 'notebook-multiple'
      },
      children: [
        {
          path: '',
          name: 'registries',
          component: () => import('@/modules/core/pages/Registries'),
          meta: {
            scope: 'registries',
            module: 'core',
            getTitle: () => i18n.t('core.router.registries'),
            icon: 'notebook-multiple',
            order: 10,
            navbar: true,
            aclChecker: () => acl.isAllowedPattern(
              acl.P.R,
              new RegExp(`^${acl.R.Registries}.*$`)
            )
          }
        },
        {
          path: 'feeding-plans',
          name: 'registries.feedingPlans',
          component: () => import('@/modules/core/pages/FeedingPlansRegistry'),
          meta: {
            scope: 'registries',
            module: 'core',
            getTitle: () => i18n.t('core.router.feedingPlansRegistry'),
            icon: 'notebook-multiple',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Registries, acl.R.FeedingPlans)
            )
          }
        },
        {
          path: 'feed-producers',
          name: 'registries.feedProducers',
          component: () => import('@/modules/core/pages/FeedProducersRegistry'),
          meta: {
            scope: 'registries',
            module: 'core',
            getTitle: () => i18n.t('core.router.feedProducersRegistry'),
            icon: 'notebook-multiple',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Registries, acl.R.FeedProducers)
            )
          }
        },
        {
          path: 'medicines',
          name: 'registries.medicines',
          component: () => import('@/modules/core/pages/MedicinesRegistry'),
          meta: {
            scope: 'registries',
            module: 'core',
            getTitle: () => i18n.t('core.router.medicinesRegistry'),
            icon: 'hospital-box-outline',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Registries, acl.R.Medicines)
            )
          }
        },
        {
          path: 'common-reasons',
          name: 'registries.commonReasons',
          component: () => import('@/modules/core/pages/CommonReasonsRegistry'),
          meta: {
            scope: 'registries',
            module: 'core',
            getTitle: () => i18n.t('core.router.commonReasonsRegistry'),
            icon: 'message-bookmark-outline',
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Registries, acl.R.CommonReasons)
            )
          }
        }
      ]
    },
    {
      path: '/administration',
      component: Layout,
      children: [
        {
          path: '',
          name: 'administration',
          component: () => import('@/modules/core/pages/Administration'),
          meta: {
            scope: 'administration',
            module: 'core',
            getTitle: () => i18n.t('core.router.administration'),
            icon: 'cog',
            order: 12,
            navbar: true
          }
        },
        {
          path: 'users',
          name: 'administration.users',
          component: () => import('@/modules/core/pages/Administration'),
          props: {
            tab: 'users'
          },
          meta: {
            scope: 'administration',
            module: 'core',
            getTitle: () => i18n.t('core.router.administration'),
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Administration, acl.R.Users)
            )
          }
        },
        {
          path: 'roles',
          name: 'administration.roles',
          component: () => import('@/modules/core/pages/Administration'),
          props: {
            tab: 'roles'
          },
          meta: {
            scope: 'administration',
            module: 'core',
            getTitle: () => i18n.t('core.router.administration'),
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Administration, acl.R.Roles)
            )
          }
        },
        {
          path: 'acl',
          name: 'administration.acl',
          component: () => import('@/modules/core/pages/Administration'),
          props: {
            tab: 'acl'
          },
          meta: {
            scope: 'administration',
            module: 'core',
            getTitle: () => i18n.t('core.router.administration'),
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Administration, acl.R.Acl)
            )
          }
        },
        {
          path: 'profile',
          name: 'administration.profile',
          component: () => import('@/modules/core/pages/Administration'),
          props: {
            tab: 'profile'
          },
          meta: {
            scope: 'administration',
            module: 'core',
            getTitle: () => i18n.t('core.router.administration')
          }
        },
        {
          path: 'auth-log',
          name: 'administration.auth-log',
          component: () => import('@/modules/core/pages/Administration'),
          props: {
            tab: 'auth-log'
          },
          meta: {
            scope: 'administration',
            module: 'core',
            getTitle: () => i18n.t('core.router.administration'),
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Administration, acl.R.AuthLog)
            )
          }
        },
        {
          path: 'preferences/factory',
          name: 'administration.preferences-factory',
          component: () => import('@/modules/core/pages/Administration'),
          props: {
            tab: 'preferences-factory'
          },
          meta: {
            scope: 'administration',
            module: 'core',
            getTitle: () => i18n.t('core.router.administration'),
            aclChecker: () => acl.isAllowed(
              acl.P.R,
              acl.R.c(acl.R.Administration, acl.R.PreferencesFactory)
            )
          }
        }
      ]
    },
    {
      path: '/control',
      component: Layout,
      meta: {
        module: 'core',
        getTitle: () => i18n.t('core.router.control'),
        icon: 'clipboard-check-outline'
      },
      children: [
        {
          path: '',
          name: 'control',
          component: () => import('@/modules/core/pages/FactoryControl'),
          meta: {
            scope: 'control',
            module: 'core',
            getTitle: () => i18n.t('core.router.control'),
            icon: 'clipboard-check-outline',
            order: 5,
            navbar: true,
            aclChecker: () => acl.isAllowedPattern(
              acl.P.R,
              new RegExp(`^${acl.R.Control}.*$`)
            )
          }
        }
      ]
    }
  ]
}
