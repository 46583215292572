export default {
  'entity.factories': 'Хозяйства',
  'entity.factory': 'Хозяйство',
  'entity.virtualSites': 'Виртуальные участки',
  'entity.virtualSite': 'Виртуальный участок',
  'entity.sites': 'Участки',
  'entity.site': 'Участок',
  'entity.tanks': 'Садки',
  'entity.tank': 'Садок',
  'app.title': 'FishWeb',
  'core.app.name': 'FishWeb',
  'core.app.description': 'Программа для предприятия аквакультуры.',
  'core.app.copyrightWithYear': '© ООО Инфорика, {{year}}. Все права защищены.',
  'core.label': 'Журнал рыбовода',
  'core.misc.newRecord': 'Новая запись',
  'core.misc.total': 'Всего',
  'core.misc.cancel': 'Отмена',
  'core.misc.close': 'Закрыть',
  'core.misc.continue': 'Продолжить',
  'core.misc.actionConfirmation': 'Подтверждение действия',
  'core.misc.save': 'Сохранить',
  'core.misc.edit': 'Редактировать',
  'core.misc.delete': 'Удалить',
  'core.misc.markAll': 'Отметить все',
  'core.misc.unmarkAll': 'Отменить все',
  'core.misc.openFullScreen': 'развернуть окно',
  'core.misc.exitFullScreen': 'свернуть окно',
  'core.misc.zoomIn': 'Увеличить масштаб',
  'core.misc.zoomOut': 'Уменьшить масштаб',
  'core.misc.zoomReset': 'Исходный масштаб',
  'core.misc.noDataInPeriod': 'Нет информации за указанный период',
  'core.module.label': 'Модуль',
  'core.module.status': 'Статус',
  'core.module.fetchModulesOperation': 'Получение списка модулей',
  'core.module.modules.core': 'Журнал Рыбовода',
  'core.module.modules.supervisor': 'Планы',
  'core.module.modules.plans': 'Планы',
  'core.module.modules.stocks': 'Склад',
  'core.module.modules.iot': 'Оборудование',
  'core.module.enabled': 'Включен',
  'core.module.enableOperation': 'Включение модуля',
  'core.module.disabled': 'Выключен',
  'core.module.disableOperation': 'Выключение модуля',
  'core.auth.form.login': 'Имя пользователя',
  'core.auth.form.password': 'Пароль',
  'core.auth.login': 'Войти',
  'core.auth.loginOperation': 'Авторизация',
  'core.auth.logout': 'Выход',
  'core.auth.log.title': 'Журнал посещений',
  'core.auth.log.date': 'Дата',
  'core.auth.log.user': 'Пользователь',
  'core.auth.log.ip': 'IP',
  'core.auth.log.agent': 'Агент',
  'core.loading.label': 'Загрузка...',
  'core.loading.fetchUserInfo': 'Получаем данные о пользователе',
  'core.loading.fetchModules': 'Получаем список модулей',
  'core.loading.fetchPreferences': 'Получаем настройки',
  'core.loading.fetchFactoryContent': '$t(entity.factory). Получаем содержимое',
  'core.loading.fetchFactoryMetrics': '$t(entity.factory). Получаем показатели',
  'core.loading.fetchSites': 'Получаем $t(entity.sites)',
  'core.loading.fetchTanks': 'Получаем $t(entity.tanks)',
  'core.loading.fetchFeeds': 'Получаем корма',
  'core.loading.fetchMedicines': 'Получаем препараты',
  'core.loading.fetchCommonReasons': 'Получаем причины операций',
  'core.loading.fetchUsers': 'Получаем список пользователей',
  'core.loading.fetchStocks': 'Получаем склады',
  'core.loading.importModule.core': 'Подключаем модуль: журнал рыбовода',
  'core.loading.importModule.plans': 'Подключаем модуль: планы',
  'core.loading.importModule.stocks': 'Подключаем модуль: склад',
  'core.loading.importModule.iot': 'Подключаем модуль: оборудование',
  'core.loading.importModule.supervisor': 'Подключаем модуль: планы',
  'core.statuses.loading': 'Загрузка',
  'core.statuses.error': 'Ошибка',
  'core.statuses.warning': 'Внимание',
  'core.router.login': 'Вход',
  'core.router.error': 'Ошибка',
  'core.router.errorWithNumber': 'Ошибка №{{number}}',
  'core.router.thermooximeters': 'Термооксиметры',
  'core.router.factory': '$t(entity.factory)',
  'core.router.factoryMap': 'Карта',
  'core.router.virtualSite': '$t(entity.virtualSite)',
  'core.router.virtualSiteFeeding': 'Показатели кормления',
  'core.router.virtualSiteFishMortality': 'Показатели отхода',
  'core.router.virtualSiteFish': 'Изменение рыбы',
  'core.router.virtualSiteFishCatch': 'Вылов рыбы',
  'core.router.site': '$t(entity.site)',
  'core.router.siteFish': 'Изменение рыбы',
  'core.router.siteFishCatch': 'Вылов рыбы',
  'core.router.siteFeeding': 'Показатели кормления',
  'core.router.siteFishMortality': 'Показатели отхода',
  'core.router.tanks': '$t(entity.factory)',
  'core.router.tankFish': 'Показатели рыбы',
  'core.router.tankFishDensity': 'Показатели плотности',
  'core.router.tankWater': 'Показатели воды',
  'core.router.tankFishMortality': 'Показатели отхода',
  'core.router.tankFishCatch': 'Показатели вылова',
  'core.router.tankFishSeeding': 'Показатели зарыбления',
  'core.router.tankFeeding': 'Показатели кормления',
  'core.router.dataInput': 'Внесение данных',
  'core.router.settings': 'Настройки',
  'core.router.settingsModules': 'Настройки',
  'core.router.settingsUsers': 'Пользователи',
  'core.router.authLog': 'Журнал посещений',
  'core.router.summary': 'Сводка',
  'core.router.notifications': 'Уведомления',
  'core.router.events': 'События',
  'core.router.reports': 'Отчёты',
  'core.router.registries': 'Справочник',
  'core.router.feedingPlansRegistry': 'Планы кормления',
  'core.router.feedProducersRegistry': 'Корма',
  'core.router.medicinesRegistry': 'Препараты',
  'core.router.commonReasonsRegistry': 'Причины операций',
  'core.router.administration': 'Панель управления',
  'core.router.acl': 'Права доступа',
  'core.router.control': 'Контроль',
  'core.calendar.mon': 'Пн',
  'core.calendar.tue': 'Вт',
  'core.calendar.wed': 'Ср',
  'core.calendar.thu': 'Чт',
  'core.calendar.fri': 'Пт',
  'core.calendar.sat': 'Сб',
  'core.calendar.sun': 'Вс',
  'core.calendar.today': 'сегодня',
  'core.calendar.nextMonth': 'следующий месяц',
  'core.calendar.prevMonth': 'предыдущий месяц',
  'core.calendar.nextYear': 'следующий год',
  'core.calendar.prevYear': 'предыдущий год',
  'core.period.label': 'Период',
  'core.period.since': 'С',
  'core.period.until': 'По',
  'core.period.prev date': 'Предыдущий день',
  'core.period.next date': 'Следующий день',
  'core.period.now': 'Сейчас',
  'core.period.today': 'Сегодня',
  'core.period.yesterday': 'Вчера',
  'core.period.week': 'Эта неделя',
  'core.period.pastWeek': 'Календ. неделя',
  'core.period.prevWeek': 'Прошлая неделя',
  'core.period.month': 'Этот месяц',
  'core.period.pastMonth': 'Календ. месяц',
  'core.period.prevMonth': 'Прошлый месяц',
  'core.period.year': 'Этот год',
  'core.period.maxSeason': 'Макс. сезон',
  'core.period.daysCount_one': '{{count}} день',
  'core.period.daysCount_few': '{{count}} дня',
  'core.period.daysCount_many': '{{count}} дней',
  'core.period.daysCount_other': '{{count}} дней',
  'core.period.pickDate': 'Укажите дату',
  'core.metrics.availability': 'Наличие',
  'core.metrics.fishAmount': 'Рыба',
  'core.metrics.fishBiomass': 'Биомасса',
  'core.metrics.fishBiomassByDay': 'Биомасса (за день)',
  'core.metrics.fishWeight': 'Навеска',
  'core.metrics.densityBiomass': 'Плотность биомассы',
  'core.metrics.densityAmount': 'Плотность рыбы',
  'core.metrics.seeding': 'Зарыбление',
  'core.metrics.mortality': 'Отход',
  'core.metrics.mortalityByDay': 'Отход (за день)',
  'core.metrics.catch': 'Вылов',
  'core.metrics.accCatch': 'Вылов',
  'core.metrics.catchByDay': 'Вылов (за день)',
  'core.metrics.accMortality': 'Отход (накопленный)',
  'core.metrics.moving': 'Пересадка',
  'core.metrics.feeding': 'Кормление',
  'core.metrics.feedRatio': 'Кормовой коэффициент',
  'core.metrics.feedingByDay': 'Кормление (за день)',
  'core.metrics.accFeeding': 'Кормление (накопленное)',
  'core.metrics.degreeday': 'Градусодни',
  'core.metrics.temperature': 'Температура',
  'core.metrics.oxygen': 'Кислород',
  'core.metrics.nitrogen': 'Азот',
  'core.metrics.no2': 'Нитриты',
  'core.metrics.no3': 'Нитраты',
  'core.metrics.nh4': 'Аммоний',
  'core.metrics.ph': 'Водород',
  'core.metrics.reset': 'Очистка',
  'core.wizard.title': 'Внесение данных',
  'core.wizard.newRecord': 'новая запись',
  'core.wizard.save to journal': 'Внести в журнал',
  'core.wizard.close': 'Закрыть',
  'core.wizard.pickForm': 'выберите форму',
  'core.wizard.types.resetTanks': 'Очистка',
  'core.dataInput.title': 'Внесение данных',
  'core.dataInput.forms.batch': 'Ежедневные данные',
  'core.dataInput.forms.water': 'Показатели воды',
  'core.dataInput.forms.feeding': 'Кормление',
  'core.dataInput.forms.moving': 'Пересадка и сортировка',
  'core.dataInput.forms.catch': 'Вылов',
  'core.dataInput.forms.mortality': 'Отход',
  'core.dataInput.forms.seeding': 'Зарыбление',
  'core.dataInput.forms.fishWeight': 'Навеска',
  'core.dataInput.forms.resetTanks': 'Очистка',
  'core.dataInput.forms.feedRation': 'Рацион',
  'core.indicator.goToTableView': 'Перейти к таблице',
  'core.indicator.tableView': 'Таблица',
  'core.indicator.goToListView': 'Перейти к списку',
  'core.indicator.listView': 'Список',
  'core.indicator.goToChartView': 'Перейти к графику',
  'core.indicator.chartView': 'График',
  'core.indicator.emptyDataByPeriod': 'Нет информации за указанный период',
  'core.indicator.summary': 'Итого',
  'core.indicator.formValidationOperation': 'Проверка формы',
  'core.indicator.saveOperation': 'Сохранение показателя',
  'core.indicator.saveManyOperation': 'Сохранение показателей',
  'core.indicator.updateOperation': 'Редактирование показателя',
  'core.indicator.deleteOperation': 'Удаление показателя',
  'core.indicator.fetchProductionIndicators': 'Получение истории показателей',
  'core.indicator.fetchWaterTemperatureIndicators': 'Получение истории показателей воды',
  'core.indicator.fetchTanksDegreeday': 'Получение градусодней',
  'core.indicator.fetchFishChangeHistory': 'Получение истории изменений рыбы',
  'core.indicator.fetchTanksMetrics': 'Получение метрик',
  'core.indicator.fetchTanksFishMetrics': 'Получение метрик рыбы',
  'core.indicator.fetchTanksFeeding': 'Получение кормлений',
  'core.indicator.fetchTanksTemperature': 'Получение температуры',
  'core.indicator.groups.fish': 'Рыба',
  'core.indicator.groups.water': 'Вода',
  'core.indicator.groups.feeding': 'Кормление',
  'core.indicator.groups.batch': 'Ежедневные данные',
  'core.indicator.groups.waterIndicators': 'Показатели воды',
  'core.indicator.batchGroups.feeding': 'Кормление',
  'core.indicator.batchGroups.mortality': 'Отход',
  'core.indicator.form.title': 'Внесение данных',
  'core.indicator.form.dataInserted': 'Данные внесены',
  'core.indicator.form.tableViewInput': 'Табличный ввод',
  'core.indicator.form.formPreparation': 'Подготовка формы',
  'core.indicator.form.indicatorEditing': 'Редактирование показателя',
  'core.indicator.form.saveChanges': 'Сохранить изменения',
  'core.indicator.form.save': 'Внести в журнал',
  'core.indicator.form.cancel': 'Отменить',
  'core.indicator.form.type': 'Тип показателя',
  'core.indicator.form.timestamp': 'Время',
  'core.indicator.form.value': 'Значение',
  'core.indicator.form.valueFeeding': 'Вес корма',
  'core.indicator.form.unit': 'Ед. измерения',
  'core.indicator.form.oxygen': 'Содержание кислорода',
  'core.indicator.form.nitrogen': 'Содержание азота',
  'core.indicator.form.temperature': 'Температура',
  'core.indicator.form.no2': 'Содержание нитритов',
  'core.indicator.form.no3': 'Содержание нитратов',
  'core.indicator.form.nh4': 'Содержание аммония',
  'core.indicator.form.ph': 'Водородный показатель',
  'core.indicator.form.degreeday': 'Градусодни',
  'core.indicator.form.fishAmount': 'Количество рыб',
  'core.indicator.form.fishWeight': 'Навеска',
  'core.indicator.form.fishBiomassDelta': 'Изменение биомассы',
  'core.indicator.form.fishBiomassDeltaSeeding': 'Биомасса зарыбления',
  'core.indicator.form.fishBiomassDeltaMortality': 'Биомасса отхода',
  'core.indicator.form.fishBiomassDeltaCatch': 'Биомасса вылова',
  'core.indicator.form.fishBiomassDeltaMoving': 'Биомасса пересадки',
  'core.indicator.form.weight': 'Вес',
  'core.indicator.form.period': 'Период',
  'core.indicator.form.feedRatio': 'КК',
  'core.indicator.form.feedRatioHelpText': 'Количество корма, необходимое для получения 1 кг прироста рыбы',
  'core.indicator.form.feedRatioFullWords': 'Кормовой коэффициент',
  'core.indicator.form.stock': 'Склад',
  'core.indicator.form.feedProducer': 'Корм',
  'core.indicator.form.feedingHandbook': 'Таблица',
  'core.indicator.form.feedingPlan': 'План кормления',
  'core.indicator.form.feedingPeriod': 'Период кормления',
  'core.indicator.form.feedingPeriodHelpText': 'Корм и препараты поровну делятся на все дни периода',
  'core.indicator.form.medicineFeeding': 'Лечебное кормление',
  'core.indicator.form.medicine': 'Препарат',
  'core.indicator.form.medicineAmount': 'Количество препарата',
  'core.indicator.form.addMedicine': 'Добавить препарат',
  'core.indicator.form.removeMedicine': 'Удалить препарат',
  'core.indicator.form.dataSubmittingWillLeadStockNegativeStateConfirmTitle': 'Внесение данных приведёт к возникновению отрицательных остатков на складах. Продолжить?',
  'core.indicator.form.tank': '$t(entity.tank)',
  'core.indicator.form.tanks': '$t(entity.tanks)',
  'core.indicator.form.empty tanks': 'Пустые $t(entity.tanks)',
  'core.indicator.form.site': '$t(entity.site)',
  'core.indicator.form.sites': '$t(entity.sites)',
  'core.indicator.form.tankGroup': 'Группа',
  'core.indicator.form.movingTankFrom': '$t(entity.tank) (откуда пересаживаем)',
  'core.indicator.form.movingTankTo': '$t(entity.tank) (куда пересаживаем)',
  'core.indicator.form.movingReason': 'Причина пересадки',
  'core.indicator.form.movingReasonPlaceholder': 'Причина пересадки',
  'core.indicator.form.movingRecords': 'Перемещения',
  'core.indicator.form.movingCorrections': 'Поправки',
  'core.indicator.form.addMovingRecord': 'Добавить пересадку',
  'core.indicator.form.removeMovingRecord': 'Удалить пересадку',
  'core.indicator.form.addMovingTank': 'Добавить $t(entity.tank)',
  'core.indicator.form.payAttentionToTanksMetrics': 'Обратите внимание на показатели',
  'core.indicator.form.reason': 'Причина',
  'core.indicator.form.reasonPlaceholder': 'Выберите причину',
  'core.indicator.form.reasonsPlaceholder': 'Выберите причины',
  'core.indicator.form.catchReasonEmpty': 'Прочее',
  'core.indicator.form.catchReason': 'Причина вылова',
  'core.indicator.form.catchReasonPlaceholder': 'Причина вылова',
  'core.indicator.form.catchingEverything': 'Полный вылов',
  'core.indicator.form.extraIndicatorsWillBeInserted': 'Будут внесены дополнительные записи',
  'core.indicator.form.seedingReason': 'Причина зарыбления',
  'core.indicator.form.seedingReasonPlaceholder': 'Причина зарыбления',
  'core.indicator.form.fishWeightReason': 'Причина изменения',
  'core.indicator.form.fishWeightReasonPlaceholder': 'Причина изменения',
  'core.indicator.form.mortalityReason': 'Причина отхода',
  'core.indicator.form.mortalityReasonPlaceholder': 'Причина отхода',
  'core.indicator.form.markTanks': 'Необходимо отметить $t(entity.tanks), для которых вносятся данные',
  'core.indicator.form.savedIndicatorsByNTanks': 'Внесены данные в $t(entity.tanks) (в {{n}} шт.)',
  'core.indicator.form.tankWithoutFish': '$t(entity.tank) "{{tank}}" пустой',
  'core.indicator.form.siteWithoutFish': '$t(entity.site) "{{site}}" пустой',
  'core.indicator.form.mortalityIndicators': 'Показатели отхода',
  'core.indicator.form.feedingIndicators': 'Показатели кормления',
  'core.indicator.form.formHasValidationErrors': 'Форма содержит ошибки',
  'core.indicator.form.formIsEmpty': 'Форма не содержит записей',
  'core.indicator.form.feedingStrategy.label': 'Стратегия',
  'core.indicator.form.feedingStrategy.helpText': 'Применить для автоматического расчета нормы кормления',
  'core.indicator.form.feedingStrategy.history': 'История',
  'core.indicator.form.feedingStrategy.handbook': 'Справочник',
  'core.indicator.form.feedingStrategy.feedingPlan': 'План кормления',
  'core.indicator.form.feedingStrategyNotMatchAllTanks': 'Стратегия кормления не применима на все $t(entity.tanks)',
  'core.indicator.form.tankPlaceholder': 'Выберите $t(entity.tank)',
  'core.indicator.form.tanksPlaceholder': 'Выберите $t(entity.tanks)',
  'core.indicator.form.sitePlaceholder': 'Выберите $t(entity.site)',
  'core.indicator.form.sitesPlaceholder': 'Выберите $t(entity.sites)',
  'core.indicator.form.comment': 'Комментарий',
  'core.indicator.form.commentPlaceholder': 'Введите комментарий',
  'core.indicator.form.extraFields': 'Дополнительные поля',
  'core.indicator.form.beforeSorting': 'Перед сортировкой',
  'core.indicator.form.afterSorting': 'После сортировки',
  'core.indicator.form.selectTank': 'Выберите $t(entity.tank)',
  'core.indicator.form.selectTanks': 'Выберите $t(entity.tanks)',
  'core.indicator.form.selectSite': 'Выберите $t(entity.site)',
  'core.indicator.form.selectSites': 'Выберите $t(entity.sites)',
  'core.indicator.form.selectNotEmptySite': 'Выберите непустой $t(entity.site)',
  'core.indicator.form.siteIsEmpty': '$t(entity.site) пустой',
  'core.indicator.form.setAllFishMetrics': 'Заполните все показатели рыбы',
  'core.indicator.form.invalidFishMetrics': 'некорректные показатели рыбы',
  'core.indicator.form.columnGroup.feeding': 'Кормление',
  'core.indicator.form.columnGroup.mortality': 'Отход',
  'core.indicator.form.columnGroup.stock': 'Склад',
  'core.indicator.form.columnGroup.feedingCalcParams': 'Параметры для расчёта',
  'core.indicator.form.columnGroup.medicineFeeding': 'Лечебное кормление',
  'core.feedingStrategy.label': 'Стратегия',
  'core.feedingStrategy.options.history': 'История',
  'core.feedingStrategy.options.handbook': 'Справочник',
  'core.feedingStrategy.options.feedingPlan': 'План кормления',
  'core.feedingStrategy.preview.feedingPlan': 'План кормления',
  'core.feedingStrategy.preview.feedProducer': 'Корм',
  'core.feedingStrategy.preview.feedingHandbook': 'Справочник',
  'core.feedingStrategy.preview.value': 'Значение',
  'core.feedingStrategy.errors.invalidFeedingStrategy': 'неизвестная стратегия',
  'core.feedingStrategy.errors.unknownError': 'неизвестная ошибка',
  'core.feedingStrategy.errors.missingTemperature': 'температура не найдена',
  'core.feedingStrategy.errors.missingHandbook': 'справочник не найден',
  'core.feedingStrategy.errors.handbookNotSuitableByFishWeight': 'справочник не подходит по навеске',
  'core.feedingStrategy.errors.missingHandbookRow': 'запись в справочнике не найдена',
  'core.feedingStrategy.errors.missingLastFeeding': 'кормлений не найдено',
  'core.feedingStrategy.errors.missingFeedingPlan': 'план кормления не найден',
  'core.feedingStrategy.errors.missingDate': 'дата не найдена',
  'core.feedingStrategy.errors.missingRulesByDate': 'правил для даты не найдено',
  'core.feedingStrategy.errors.missingRuleByFishWeight': 'правило для навески не найдено',
  'core.feedingStrategy.errors.fishMetricsAreIncorrect': 'показатели рыбы некорректны',
  'core.feedingStrategy.errors.tooSmallValue': 'Слишком малое значение корма',
  'core.factories.label': '$t(entity.factories)',
  'core.factories.add': 'Внести $t(entity.factory)',
  'core.factories.deleteConfirmText': '$t(entity.factory) "{{name}}" будет удалено. Продолжить?',
  'core.factories.form.title': '$t(entity.factory)',
  'core.factories.form.name': 'Название',
  'core.factories.form.server': 'Сервер',
  'core.factories.brief.title': '$t(entity.factories)',
  'core.factories.brief.factoryAnalysing': 'Обработка данных: {{name}}',
  'core.factories.brief.totalSites': '$t(entity.sites)',
  'core.factories.brief.totalTanks': '$t(entity.tanks)',
  'core.factories.brief.totalFishAmount': 'Рыба',
  'core.factories.brief.totalBiomass': 'Биомасса',
  'core.factories.brief.seasonSaleBiomass': 'Продажа',
  'core.factories.brief.seasonSaleAmount': 'Продажа',
  'core.feedProducer.registry': 'Корма',
  'core.feedProducer.userFeedProducers': 'Используемые корма',
  'core.feedProducer.systemFeedProducers': 'Справочник кормов',
  'core.feedProducer.filterByFeeds': 'Фильтр по кормам',
  'core.feedProducer.feedPlaceholder': 'Выберите корм',
  'core.feedProducer.feedsPlaceholder': 'Выберите корма',
  'core.feedProducer.label': 'Корма',
  'core.feedProducer.all': 'Все корма',
  'core.feedProducer.create': 'Добавить корм',
  'core.feedProducer.import': 'Импорт корма',
  'core.feedProducer.importFeed': 'Загрузить корм',
  'core.feedProducer.exportFeed': 'Выгрузить корм',
  'core.feedProducer.copyToUserFeeds': 'Скопировать в используемые',
  'core.feedProducer.addHandbook': 'Добавить таблицу кормления',
  'core.feedProducer.deleteHandbook': 'Удалить таблицу кормления',
  'core.feedProducer.fetchManyOperation': 'Получение списка кормов',
  'core.feedProducer.fetchFeedProducersOperation': 'Получение используемых кормов',
  'core.feedProducer.fetchSystemFeedProducersOperation': 'Получение справочника кормов',
  'core.feedProducer.copySystemFeedProducerOperation': 'Копирование корма',
  'core.feedProducer.feedProducerCopySuccess': 'Корм скопирован',
  'core.feedProducer.feedProducersNotFound': 'Корма отсутствуют',
  'core.feedProducer.importParseError': 'Не удалось прочитать корм',
  'core.feedProducer.updateFeedProducerOperation': 'Обновление корма',
  'core.feedProducer.createFeedProducerOperation': 'Добавление корма',
  'core.feedProducer.deleteFeedProducerOperation': 'Удаление корма',
  'core.feedProducer.deleteFeedProducerSuccess': 'Корм удалён',
  'core.feedProducer.deleteConfirmText': 'Корм будет удален. Продолжить?',
  'core.feedProducer.makeCopyConfirmText': 'Корм будет скопирован в $t(entity.factory). Продолжить?',
  'core.feedProducer.makeCopy': 'Скопировать',
  'core.feedProducer.isCopied': 'скопирован',
  'core.feedProducer.totalConsumption': 'Всего израсходовано, кг',
  'core.feedProducer.validation.requireTemperature': 'Добавьте температуру',
  'core.feedProducer.validation.requireFishWeight': 'Добавьте навеску',
  'core.feedProducer.validation.requireHandbook': 'Заполните таблицу кормления',
  'core.feedProducer.handbook.label': 'Суточная норма кормления',
  'core.feedProducer.handbook.name': 'Наименование',
  'core.feedProducer.handbook.defaultNameWithNumber': 'Таблица {{number}}',
  'core.feedProducer.handbook.fishWeight': 'Навеска, кг.',
  'core.feedProducer.handbook.feedRatio': 'КК',
  'core.feedProducer.handbook.usedInFeedingPlans': 'Таблица кормления используется в планах кормления.',
  'core.feedProducer.handbook.export': 'Выгрузить таблицу кормления',
  'core.feedProducer.handbook.import': 'Загрузить таблицу кормления',
  'core.feedProducer.handbook.importOperation': 'Загрузка таблицы кормления',
  'core.feedProducer.handbook.importParseError': 'Не удалось прочитать таблицу кормления',
  'core.feedProducer.handbook.delete': 'Удалить таблицу',
  'core.feedProducer.handbook.deleteConfirmText': 'Таблица кормления "{{name}}" будет удалена, продолжить?',
  'core.feedProducer.form.title': 'Корм',
  'core.feedProducer.form.addTemperature': 'Добавить температуру',
  'core.feedProducer.form.addFishWeight': 'Добавить навеску',
  'core.feedProducer.form.tabs.general': 'Основное',
  'core.feedProducer.form.tabs.handbook': 'Суточная норма кормления',
  'core.feedProducer.form.name': 'Наименование',
  'core.feedProducer.form.manufactor': 'Производитель',
  'core.feedProducer.form.model': 'Модель',
  'core.feedProducer.form.meta': 'Особенности',
  'core.feedProducer.origin.label': 'Источник',
  'core.feedProducer.origin.usedInFeedingPlans': 'Изменяемый корм используется в планах кормления.',
  'core.feedProducer.origin.whatFeedToUse?': 'Какой корм использовать в планах кормления?',
  'core.feedProducer.origin.useUserOrigin': 'использовать этот корм',
  'core.feedProducer.origin.user': 'Пользователь',
  'core.feedProducer.origin.useSystemOrigin': 'оставить корм из справочника',
  'core.feedProducer.origin.system': 'Справочник',
  'core.feedingPlan.filterByPlans': 'Фильтр по планам',
  'core.feedingPlan.planPlaceholder': 'Выберите план кормления',
  'core.feedingPlan.plansPlaceholder': 'Выберите планы кормления',
  'core.feedingPlan.title': 'Планы кормления',
  'core.medicine.title': 'Препараты',
  'core.medicine.create': 'Добавить препарат',
  'core.medicine.fetchMedicinesOperation': 'Получение препаратов',
  'core.medicine.updateMedicineOperation': 'Обновление препарата',
  'core.medicine.createMedicineOperation': 'Добавление препарата',
  'core.medicine.deleteMedicineOperation': 'Удаление препарата',
  'core.medicine.deleteMedicineSuccess': 'Препарат удалён',
  'core.medicine.deleteConfirmText': 'Препарат будет удален. Продолжить?',
  'core.medicine.medicinesNotFound': 'Препараты отсутствуют',
  'core.medicine.medicinePlaceholder': 'Выберите препарат',
  'core.medicine.medicinesPlaceholder': 'Выберите препараты',
  'core.medicine.form.title': 'Препарат',
  'core.medicine.form.type': 'Тип',
  'core.medicine.form.name': 'Наименование',
  'core.medicine.type.drug': 'Лекарство',
  'core.medicine.type.vitamin': 'Витамин',
  'core.medicine.type.disinfection': 'Средство дезинфекции',
  'core.commonReason.title': 'Причины операций',
  'core.commonReason.create': 'Добавить причину',
  'core.commonReason.fetchCommonReasonsOperation': 'Получение причин',
  'core.commonReason.updateCommonReasonOperation': 'Обновление причины',
  'core.commonReason.createCommonReasonOperation': 'Добавление причины',
  'core.commonReason.deleteCommonReasonOperation': 'Удаление причины',
  'core.commonReason.deleteCommonReasonSuccess': 'Причина удалена',
  'core.commonReason.deleteConfirmText': 'Причина будет удалена. Продолжить?',
  'core.commonReason.commonReasonsNotFound': 'Причины отсутствуют',
  'core.commonReason.commonReasonPlaceholder': 'Выбрать причину',
  'core.commonReason.commonReasonsPlaceholder': 'Выбрать причины',
  'core.commonReason.scopes.catch': 'Вылов',
  'core.commonReason.scopes.seeding': 'Зарыбление',
  'core.commonReason.scopes.fishWeight': 'Навеска',
  'core.commonReason.scopes.mortality': 'Отход',
  'core.commonReason.scopes.moving': 'Пересадка',
  'core.commonReason.scopes.stockOutcome': 'Списание со склада',
  'core.commonReason.form.title': 'Причина',
  'core.commonReason.form.label': 'Причина',
  'core.commonReason.form.labelPlaceholder': 'Введите причину',
  'core.commonReason.form.scope': 'Операция',
  'core.commonReason.form.scopes': 'Операции',
  'core.commonReason.form.scopesPlaceholder': 'Выберите операции',
  'core.site.single': '$t(entity.site)',
  'core.site.singleWithName': '$t(entity.site) {{name}}',
  'core.site.sitePlaceholder': 'Выберите $t(entity.site)',
  'core.site.sitesPlaceholder': 'Выберите $t(entity.sites)',
  'core.site.plural': '$t(entity.sites)',
  'core.site.fetchManyOperation': '$t(entity.sites). Получение списка',
  'core.site.title': '$t(entity.site) {{name}}',
  'core.site.deleteTanksToDeleteSite': 'Нельзя удалить $t(entity.site), пока на нем есть хотя бы один $t(entity.tank)',
  'core.site.createOperation': '$t(entity.site). Создание',
  'core.site.updateOperation': '$t(entity.site). Изменение',
  'core.site.deleteOperation': '$t(entity.site). Удаление',
  'core.site.delete': 'Удалить $t(entity.site)',
  'core.site.deleteSiteConfirmText': 'Вы действительно хотите удалить $t(entity.site) "{{name}}"?',
  'core.site.brief.fetchOperation': '$t(entity.site). Получение сводки',
  'core.site.brief.fetchFeedingByDayOperation': 'Получения информации по кормлению за день',
  'core.site.brief.fetchMortalityByDayOperation': 'Получения информации по отходу за день',
  'core.site.brief.title': 'Сводка: $t(entity.site)',
  'core.site.brief.tanks': '$t(entity.tanks)',
  'core.site.brief.fishAmount': 'Рыба',
  'core.site.brief.fishBiomass': 'Биомасса',
  'core.site.brief.avgWeight': 'Ср. вес',
  'core.site.brief.catch': 'Вылов',
  'core.site.brief.feeding': 'Кормление',
  'core.site.brief.mortality': 'Отход',
  'core.site.brief.avgTemperature': 'Температура (ср.)',
  'core.site.brief.avgOxygen': 'Кислород (ср.)',
  'core.site.brief.avgNitrogen': 'Азот (ср.)',
  'core.site.brief.avgNo2': 'Нитриты (ср.)',
  'core.site.brief.avgNo3': 'Нитраты (ср.)',
  'core.site.brief.avgNh4': 'Аммоний (ср.)',
  'core.site.brief.avgPh': 'Водород (ср.)',
  'core.site.form.save': 'Сохранить',
  'core.site.form.cancel': 'Отмена',
  'core.site.form.name': 'Название',
  'core.site.form.color': 'Цвет на карте',
  'core.site.notFoundErrorMessage': '$t(entity.site): не найдено',
  'core.virtualSite.single': '$t(entity.virtualSite)',
  'core.virtualSite.singleWithName': '$t(entity.virtualSite) {{name}}',
  'core.virtualSite.plural': '$t(entity.virtualSites)',
  'core.virtualSite.fetchManyOperation': '$t(entity.virtualSites). Получение списка',
  'core.virtualSite.notFoundErrorMessage': '$t(entity.virtualSite): не найдено',
  'core.virtualSite.createOperation': '$t(entity.virtualSite). Создание',
  'core.virtualSite.updateOperation': '$t(entity.virtualSite). Изменение',
  'core.virtualSite.deleteOperation': '$t(entity.virtualSite). Удаление',
  'core.virtualSite.delete': 'Удалить $t(entity.virtualSite)',
  'core.virtualSite.deleteSiteConfirmText': 'Вы действительно хотите удалить $t(entity.virtualSite) "{{name}}"?',
  'core.virtualSite.form.save': 'Сохранить',
  'core.virtualSite.form.cancel': 'Отмена',
  'core.virtualSite.form.name': 'Название',
  'core.virtualSite.form.tankIds': '$t(entity.tanks)',
  'core.virtualSite.form.tanksTimeLimits': 'Периоды включения',
  'core.virtualSite.form.addPeriod': 'Добавить период',
  'core.virtualSite.form.deletePeriod': 'Удалить период',
  'core.virtualSite.form.color': 'Цвет на карте',
  'core.tank.single': '$t(entity.tank)',
  'core.tank.plural': '$t(entity.tanks)',
  'core.tank.tankPlaceholder': 'Выберите $t(entity.tank)',
  'core.tank.tanksPlaceholder': 'Выберите $t(entity.tanks)',
  'core.tank.emptyCounter_one': 'пустой',
  'core.tank.emptyCounter_few': 'пустых',
  'core.tank.emptyCounter_many': 'пустых',
  'core.tank.emptyCounter_other': 'пустых',
  'core.tank.tanksNumber': '$t(entity.tanks)',
  'core.tank.fetchManyOperation': '$t(entity.tanks). Получение списка',
  'core.tank.brief.title': 'Сводка: $t(entity.tank)',
  'core.tank.brief.fishAmount': 'Рыба',
  'core.tank.brief.fishBiomass': 'Биомасса',
  'core.tank.brief.fishWeight': 'Навеска',
  'core.tank.brief.density': 'Плотность',
  'core.tank.brief.catch': 'Вылов',
  'core.tank.brief.temperature': 'Температура',
  'core.tank.brief.oxygen': 'Кислород',
  'core.tank.brief.nitrogen': 'Азот',
  'core.tank.brief.no2': 'Нитриты',
  'core.tank.brief.no3': 'Нитраты',
  'core.tank.brief.nh4': 'Аммоний',
  'core.tank.brief.ph': 'Водород',
  'core.tank.brief.degreeday': 'Градусодни',
  'core.tank.brief.feeding': 'Кормление',
  'core.tank.brief.mortality': 'Отход',
  'core.tank.brief.seeding': 'Зарыбление',
  'core.tank.size.title': 'Размеры',
  'core.tank.size.missing': 'Размеры не заданы',
  'core.tank.size.box': 'Размеры: {{length}} x {{width}} x {{height}}',
  'core.tank.size.length': 'Длина',
  'core.tank.size.width': 'Ширина',
  'core.tank.size.height': 'Высота',
  'core.tank.size.updateOperation': 'Изменение размеров',
  'core.tank.size.updateSuccessText': 'Размеры обновлены',
  'core.tank.form.save': 'Сохранить',
  'core.tank.form.cancel': 'Отмена',
  'core.tank.form.name': 'Название',
  'core.tank.form.color': 'Цвет на карте',
  'core.tank.form.positionNumber': 'Порядковый номер',
  'core.tank.form.positionNumberHelpText': 'Позиция при выводе списков',
  'core.tank.form.site': '$t(entity.site)',
  'core.tank.form.virtualSite': '$t(entity.virtualSite)',
  'core.tank.form.positionNumberShouldBeBetween': 'Порядковый номер должен быть от {{min}} до {{max}}',
  'core.tank.form.positionNumberIsReserved': 'Порядковый номер занят',
  'core.tank.form.nameShouldBeUnique': '$t(entity.site) уже содержит $t(entity.tank) с таким именем',
  'core.tank.deleteTankConfirmText': 'Вы действительно хотите удалить $t(entity.tank)?',
  'core.tank.deleteTankResetWarning': 'Перед удалением он будет очищен.',
  'core.tank.delete': 'Удалить $t(entity.tank)',
  'core.tank.resetTankConfirmText': 'Вы действительно хотите очистить $t(entity.tank)?',
  'core.tank.reset': 'Очистить $t(entity.tank)',
  'core.tank.resetOperation': 'Очистка',
  'core.tank.createOperation': '$t(entity.tank). Создание',
  'core.tank.updateOperation': '$t(entity.tank). Изменение',
  'core.tank.deleteOperation': '$t(entity.tank). Удаление',
  'core.tank.cannotDeleteText': 'Не удалось удалить $t(entity.tank)',
  'core.tank.notFoundErrorMessage': '$t(entity.tank). Не найдено',
  'core.tank.mapStates.added': 'Добавлен',
  'core.tank.mapStates.removed': 'Удален',
  'core.tank.mapStates.moved': 'Перемещен',
  'core.factory.single': '$t(entity.factory)',
  'core.factory.plural': '$t(entity.factories)',
  'core.factory.goToFactoryMap': 'перейти к карте',
  'core.factory.createTank': '$t(entity.tank): создать',
  'core.factory.createSite': '$t(entity.site): создать',
  'core.factory.createVirtualSite': '$t(entity.virtualSite): создать',
  'core.factory.brief.factory': '$t(entity.factory)',
  'core.factory.brief.totalSites': '$t(entity.sites)',
  'core.factory.brief.totalTanks': '$t(entity.tanks)',
  'core.factory.brief.totalFishAmount': 'Рыба',
  'core.factory.brief.totalBiomass': 'Биомасса',
  'core.factory.brief.seasonSaleBiomass': 'Продажа',
  'core.factory.brief.seasonSaleAmount': 'Продажа',
  'core.factory.editor.batchFormTitle': '$t(entity.tanks) и $t(entity.sites): массовое создание',
  'core.factory.editor.openBatchForm': '$t(entity.tanks) и $t(entity.sites): массовое создание',
  'core.factory.editor.add': 'Добавить',
  'core.factory.editor.tanksLocations': 'Садки: расположение',
  'core.factory.editor.siteName': 'Наименование',
  'core.factory.editor.siteColor': 'Цвет на карте',
  'core.factory.editor.addSite': 'Добавить $t(entity.site)',
  'core.factory.editor.deleteSite': 'Удалить $t(entity.site)',
  'core.factory.editor.deleteSiteConfirmText': 'Вы действительно хотите удалить $t(entity.site) "{{name}}"?',
  'core.factory.editor.addTank': 'Добавить $t(entity.tank)',
  'core.factory.editor.deleteTank': 'Удалить $t(entity.tank)',
  'core.factory.editor.deleteTankConfirmText': 'Вы действительно хотите удалить $t(entity.tank) "{{name}}"?',
  'core.factory.editor.tankName': 'Наименование',
  'core.factory.editor.formHasValidationErrors': 'Форма содержит ошибки',
  'core.factory.map.label': 'Карта',
  'core.factory.map.editor': 'Редактор',
  'core.factory.map.helpText': 'Нажмите на $t(entity.site)/$t(entity.tank) для просмотра сводки',
  'core.factory.map.fetchOperation': 'Получение карты',
  'core.factory.map.saveOperation': 'Сохранение карты',
  'core.factory.map.edit': 'редактировать карту',
  'core.factory.map.reset': 'Расставить по умолчанию',
  'core.factory.map.save': 'Сохранить карту',
  'core.factory.map.cancel': 'Отмена',
  'core.factory.map.fetchErrorText': 'Не удалось получить карту',
  'core.factory.showEmptySites': 'Показывать пустые',
  'core.factoryStatus.control': 'Контроль',
  'core.factoryStatus.fillControlByDate': 'Контроль заполнения за {{date}}',
  'core.factoryStatus.fetchPreferencesOperation': 'Получение настроек контроля',
  'core.factoryStatus.updatePreferencesOperation': 'Обновление настроек контроля',
  'core.factoryStatus.fetchControlDataOperation': 'Получение данных',
  'core.factoryStatus.it can take a while': 'Это может занять некоторое время',
  'core.factoryStatus.data may not be up to date': 'Данные могут быть не актуальными.',
  'core.factoryStatus.update data': 'Обновить данные.',
  'core.factoryStatus.journal.object': 'Объект',
  'core.factoryStatus.journal.feeding': 'Кормление',
  'core.factoryStatus.journal.feed': 'Корм',
  'core.factoryStatus.journal.mortality': 'Отход',
  'core.factoryStatus.journal.temperature': 'Температура',
  'core.factoryStatus.journal.oxygen': 'Кислород',
  'core.factoryStatus.journal.nitrogen': 'Азот',
  'core.factoryStatus.journal.no2': 'Нитриты',
  'core.factoryStatus.journal.no3': 'Нитраты',
  'core.factoryStatus.journal.nh4': 'Аммоний',
  'core.factoryStatus.journal.ph': 'Водород',
  'core.factoryStatus.journal.stockBinding': 'Склад',
  'core.factoryStatus.stocks.stock': 'Склад',
  'core.factoryStatus.stocks.negativeRemaining': 'Отрицательные остатки',
  'core.factoryStatus.stocks.binding': 'Привязка садков',
  'core.factoryStatus.plans.object': 'Объект',
  'core.factoryStatus.plans.hasPlan': 'Наличие плана',
  'core.factoryStatus.scope.factory': 'Журнал рыбовода',
  'core.factoryStatus.scope.stock': 'Склад',
  'core.factoryStatus.scope.plans': 'Планы',
  'core.factoryStatus.ok': 'ОК',
  'core.factoryStatus.missingData': 'Нет данных!',
  'core.factoryStatus.notEnoughData': 'Не хватает данных!',
  'core.factoryStatus.hasMismatchData': 'Есть несоответствия данных',
  'core.factoryStatus.feedingAmountDifferBy{{deltaPercent}}': 'Кормление отличается от расчетного >{{deltaPercent}}%',
  'core.factoryStatus.requireFeedChange': 'Требуется смена корма',
  'core.factoryStatus.metricMatchNorm': 'Показатель в норме',
  'core.factoryStatus.metricNotMatchNorm': 'Показатель не соответствует норме',
  'core.factoryStatus.notAllMetricsMatchNorm': 'Не все показатели соответствуют норме',
  'core.factoryStatus.allMetricsMatchNorm': 'Все показатели в норме',
  'core.factoryStatus.tankHasNotStockBinding': 'Нет привязки к складу',
  'core.factoryStatus.hasTanksWithoutStockBinding': '$t(entity.tanks) без привязки к складу',
  'core.factoryStatus.haveProblems': 'Есть проблемы!',
  'core.factoryStatus.hasNegativeRemaining': 'Отрицательные остатки!',
  'core.factoryStatus.bindingsAreNotSet': 'Привязки не установлены!',
  'core.factoryStatus.missingPlans': 'Не хватает планирования!',
  'core.factoryStatus.plansHaveMismatch': 'Несоответствие производственных планов',
  'core.factoryStatus.haveNotPlan': 'Нет производственного плана!',
  'core.factoryStatus.planFactDifferBy{{deltaPercent}}': 'Расхождение план-факт >{{deltaPercent}}%',
  'core.factoryStatus.hasTanksWithoutPlan': '$t(entity.tanks) без планирования',
  'core.factorySummary.getFactoryContentOperation': '$t(entity.factory). Получение содержимого',
  'core.factorySummary.by.feeding': 'Кормление',
  'core.factorySummary.by.feedingGrowth': 'Прирост',
  'core.factorySummary.by.medicines': 'Препараты',
  'core.factorySummary.by.mortality': 'Отход',
  'core.factorySummary.by.catch': 'Вылов',
  'core.factorySummary.total': 'Всего',
  'core.factorySummary.year': 'Год',
  'core.catchSummary.getSummaryOperation': 'Получение сводки по вылову',
  'core.mortalitySummary.getSummaryOperation': 'Получение сводки по отходу',
  'core.feedingSummary.details': 'Детализация',
  'core.feedingSummary.feed': 'Корм',
  'core.feedingSummary.total': 'Итого',
  'core.feedingSummary.getSummaryOperation': 'Получение сводки по кормлению',
  'core.feedingSummary.fetchFactSummary': 'Получение фактического потребления кормов',
  'core.feedingSummary.fetchPlanSummary': 'Получение планового потребления кормов',
  'core.feedingGrowthSummary.getSummaryOperation': 'Получение сводки по приросту',
  'core.feedingGrowthSummary.fetchFactSummary': 'Получение фактического прироста',
  'core.feedingGrowthSummary.fetchPlanSummary': 'Получение планового прироста',
  'core.feedingGrowthSummary.growth': 'прирост',
  'core.feedingGrowthSummary.feedRatio': 'КК',
  'core.medicinesSummary.getSummaryOperation': 'Получение сводки по препаратам',
  'core.siteFish.title': 'Изменение рыбы',
  'core.siteFish.fetchOperation': 'Получение истории изменений рыбы ($t(entity.site))',
  'core.siteFish.table.timestamp': 'Время',
  'core.siteFish.table.tank': '$t(entity.tank)',
  'core.siteFish.table.fishBiomass': 'Биомасса',
  'core.siteFish.table.fishAmount': 'Рыба',
  'core.siteFish.table.fishWeight': 'Ср. навеска',
  'core.siteFish.noDataInPeriod': 'Нет информации за указанный период',
  'core.siteFishCatch.title': 'Вылов рыбы',
  'core.siteFishCatch.fetchOperation': 'Получение истории вылова рыбы ($t(entity.site))',
  'core.siteFishCatch.table.timestamp': 'Время',
  'core.siteFishCatch.table.tank': '$t(entity.tank)',
  'core.siteFishCatch.table.value': 'Значение показателя',
  'core.siteFishCatch.table.unit': 'Ед. измерения',
  'core.siteFishCatch.table.fishBiomassDelta': 'Биомасса показателя',
  'core.siteFishCatch.table.reason': 'Причина',
  'core.siteFishCatch.table.type': 'Показатель',
  'core.siteFishCatch.noDataInPeriod': 'Нет информации за указанный период',
  'core.siteFeeding.title': 'Кормление',
  'core.siteFeeding.fetchOperation': 'Получение истории кормления рыбы ($t(entity.site))',
  'core.siteFeeding.chart': 'График кормления',
  'core.siteFeeding.summary.totalFeedingCounter': 'Всего кормлений',
  'core.siteFeeding.summary.totalFeedingAmount': 'Всего корма',
  'core.siteFeeding.summary.totalMedicineAmount': 'Всего препаратов',
  'core.siteFeeding.summary.avgPerDay': 'В ср. за день',
  'core.siteFeeding.summary.consumption': 'Детализация',
  'core.siteFeeding.summary.feedProducer': 'Корм',
  'core.siteFeeding.summary.medicine': 'Препарат',
  'core.siteFeeding.table.timestamp': 'Время',
  'core.siteFeeding.table.tank': '$t(entity.tank)',
  'core.siteFeeding.table.value': 'Показатель',
  'core.siteFeeding.table.unit': 'Ед. измерения',
  'core.siteFeeding.table.stock': 'Склад',
  'core.siteFeeding.table.feedProducer': 'Корм',
  'core.siteFeeding.table.feedRatio': 'КК',
  'core.siteFeeding.table.medicine': 'Препарат',
  'core.siteFeeding.table.weight': 'Вес',
  'core.siteFeeding.noDataInPeriod': 'Нет информации за указанный период',
  'core.siteFishMortality.title': 'Отход',
  'core.siteFishMortality.fetchOperation': 'Получение истории отхода рыбы ($t(entity.site))',
  'core.siteFishMortality.summary.total': 'Всего',
  'core.siteFishMortality.summary.details': 'Детализация',
  'core.siteFishMortality.summary.mortalityByDay': 'В ср. за день',
  'core.siteFishMortality.summary.percentSafety': 'От числа рыбы на начало периода',
  'core.siteFishMortality.table.timestamp': 'Время',
  'core.siteFishMortality.table.tank': '$t(entity.tank)',
  'core.siteFishMortality.table.value': 'Отход',
  'core.siteFishMortality.table.unit': 'Ед. измерения',
  'core.siteFishMortality.table.reason': 'Причина',
  'core.siteFishMortality.table.fishBiomassDelta': 'Биомасса показателя',
  'core.siteFishMortality.table.fishAmount': 'Количество рыбы (после)',
  'core.siteFishMortality.noDataInPeriod': 'Нет информации за указанный период',
  'core.tankFeeding.title': 'Кормление',
  'core.tankFeeding.fetchOperation': 'Получение истории кормления рыбы ($t(entity.tank))',
  'core.tankFeeding.fetchStockTransfersOperation': 'Получение движений на складах',
  'core.tankFeeding.chart': 'График кормления',
  'core.tankFeeding.summary.totalFeedingCounter': 'Всего кормлений',
  'core.tankFeeding.summary.totalFeedingAmount': 'Всего корма',
  'core.tankFeeding.summary.totalMedicineAmount': 'Всего препаратов',
  'core.tankFeeding.summary.avgPerDay': 'В ср. за день',
  'core.tankFeeding.summary.consumption': 'Детализация',
  'core.tankFeeding.summary.feedProducer': 'Корм',
  'core.tankFeeding.summary.medicine': 'Препарат',
  'core.tankFeeding.table.timestamp': 'Время',
  'core.tankFeeding.table.value': 'Показатель',
  'core.tankFeeding.table.unit': 'Ед. измерения',
  'core.tankFeeding.table.stock': 'Склад',
  'core.tankFeeding.table.feedProducer': 'Корм',
  'core.tankFeeding.table.feedRatio': 'КК',
  'core.tankFeeding.table.medicine': 'Препарат',
  'core.tankFeeding.table.weight': 'Вес',
  'core.tankFeeding.table.comment': 'Комментарий',
  'core.tankFeeding.noDataInPeriod': 'Нет информации за указанный период',
  'core.tankFish.title': 'Изменение рыбы',
  'core.tankFish.fetchOperation': 'Получение истории изменений рыбы ($t(entity.tank))',
  'core.tankFish.table.timestamp': 'Время',
  'core.tankFish.table.value': 'Значение',
  'core.tankFish.table.unit': 'Ед. измерения',
  'core.tankFish.table.stock': 'Склад',
  'core.tankFish.table.feedProducer': 'Корм',
  'core.tankFish.table.feedRatio': 'КК',
  'core.tankFish.table.medicine': 'Препарат',
  'core.tankFish.table.medicineAmount': 'Количество препарата',
  'core.tankFish.table.fishBiomassDelta': 'Биомасса показателя',
  'core.tankFish.table.type': 'Показатель',
  'core.tankFish.table.reason': 'Причина',
  'core.tankFish.table.tanks': '$t(entity.tanks)',
  'core.tankFish.table.comment': 'Комментарий',
  'core.tankFish.table.fishAmount': 'Количество рыбы',
  'core.tankFish.table.fishBiomass': 'Биомасса',
  'core.tankFish.table.fishWeight': 'Навеска',
  'core.tankFish.noDataInPeriod': 'Нет информации за указанный период',
  'core.tankFishCatch.title': 'Вылов рыбы',
  'core.tankFishCatch.fetchOperation': 'Получение истории вылова рыбы ($t(entity.tank))',
  'core.tankFishCatch.table.timestamp': 'Время',
  'core.tankFishCatch.table.value': 'Вылов',
  'core.tankFishCatch.table.unit': 'Ед. измерения',
  'core.tankFishCatch.table.fishBiomassDelta': 'Биомасса показателя',
  'core.tankFishCatch.table.type': 'Показатель',
  'core.tankFishCatch.table.comment': 'Комментарий',
  'core.tankFishCatch.noDataInPeriod': 'Нет информации за указанный период',
  'core.tankFishDensity.title': 'Изменение плотности рыбы',
  'core.tankFishDensity.fetchOperation': 'Получение истории изменения плотности рыбы ($t(entity.tank))',
  'core.tankFishDensity.table.timestamp': 'Время',
  'core.tankFishDensity.table.densityBiomass': 'Плотность биомассы',
  'core.tankFishDensity.table.densityAmount': 'Плотность рыбы',
  'core.tankFishDensity.noDataInPeriod': 'Нет информации за указанный период',
  'core.tankFishMortality.title': 'Отход',
  'core.tankFishMortality.fetchOperation': 'Получение истории отхода рыбы ($t(entity.tank))',
  'core.tankFishMortality.summary.total': 'Всего',
  'core.tankFishMortality.summary.mortalityByDay': 'В ср. за день',
  'core.tankFishMortality.summary.percentSafety': 'От числа рыбы на начало периода',
  'core.tankFishMortality.table.timestamp': 'Время',
  'core.tankFishMortality.table.value': 'Отход',
  'core.tankFishMortality.table.unit': 'Ед. измерения',
  'core.tankFishMortality.table.fishBiomassDelta': 'Биомасса показателя',
  'core.tankFishMortality.table.fishAmount': 'Количество рыбы (после)',
  'core.tankFishMortality.table.comment': 'Комментарий',
  'core.tankFishMortality.noDataInPeriod': 'Нет информации за указанный период',
  'core.tankFishSeeding.title': 'Зарыбление',
  'core.tankFishSeeding.fetchOperation': 'Получение истории зарыбления ($t(entity.tank))',
  'core.tankFishSeeding.table.timestamp': 'Время',
  'core.tankFishSeeding.table.value': 'Зарыбление',
  'core.tankFishSeeding.table.unit': 'Ед. измерения',
  'core.tankFishSeeding.table.fishBiomassDelta': 'Биомасса показателя',
  'core.tankFishSeeding.table.fishAmount': 'Количество рыбы (после)',
  'core.tankFishSeeding.table.comment': 'Комментарий',
  'core.tankFishSeeding.noDataInPeriod': 'Нет информации за указанный период',
  'core.tankWater.title': 'Показатели воды',
  'core.tankWater.fetchOperation': 'Получение истории показателей воды ($t(entity.tank))',
  'core.tankWater.table.timestamp': 'Время',
  'core.tankWater.table.value': 'Значение',
  'core.tankWater.table.unit': 'Ед. измерения',
  'core.tankWater.table.type': 'Показатель',
  'core.tankWater.noDataInPeriod': 'Нет информации за указанный период',
  'core.user.password': 'Пароль',
  'core.user.notifications': 'Уведомления',
  'core.user.control': 'Контроль',
  'core.user.changePassword': 'Изменить пароль',
  'core.user.updatePasswordOperation': 'Смена пароля',
  'core.user.profilePage.title': 'Профиль',
  'core.user.passwordNew.title': 'Новый пароль',
  'core.user.passwordNew.oldPassword': 'Старый пароль',
  'core.user.passwordNew.newPassword': 'Новый пароль',
  'core.user.passwordNew.newPasswordConfirm': 'Повторите новый пароль',
  'core.user.passwordNew.passwordsShouldMatch': 'пароли должны совпадать',
  'core.user.passwordNew.newPasswordIsSet': 'Установлен новый пароль',
  'core.notifications.title': 'Уведомления',
  'core.notifications.fetchOperation': 'Получение уведомлений',
  'core.notifications.fetchPreferencesOperation': 'Получение настроек уведомлений',
  'core.notifications.updatePreferencesOperation': 'Обновление настроек уведомлений',
  'core.notifications.markOneAsReadOperation': 'Смена статуса уведомления',
  'core.notifications.markManyAsReadOperation': 'Смена статуса уведомлений',
  'core.notifications.noNotificationsYet': 'Пока уведомлений нет',
  'core.notifications.category.new': 'Новые',
  'core.notifications.category.archive': 'Архив',
  'core.notifications.table.timestamp': 'Время',
  'core.notifications.table.content': 'Уведомление',
  'core.notifications.type.requireFeedChange': 'Необходимо сменить корм',
  'core.notifications.type.temperatureOutOfNorm': 'Температура не в норме',
  'core.notifications.type.oxygenOutOfNorm': 'Кислород не в норме',
  'core.notifications.type.phOutOfNorm': 'Водород не в норме',
  'core.notifications.type.nitrogenOutOfNorm': 'Азот не в норме',
  'core.notifications.type.nh4OutOfNorm': 'Аммоний не в норме',
  'core.notifications.type.no2OutOfNorm': 'Нитриты не в норме',
  'core.notifications.type.no3OutOfNorm': 'Нитраты не в норме',
  'core.notifications.type.stockTransferByFeedingError': 'Ошибка списаний со склада при кормлении',
  'core.notifications.type.soonRunOutOfFeedOnStock': 'Заканчиваются запасы корма на складе',
  'core.notifications.type.soonRunOutOfMedicineOnStock': 'Заканчиваются запасы препарата на складе',
  'core.notifications.type.system': 'FishWeb',
  'core.notifications.preferences.title': 'Настройки',
  'core.notifications.preferences.thresholdValue': 'Пороговое значение',
  'core.notifications.preferences.sendNotifications': 'Отправлять уведомления',
  'core.events.title': 'События',
  'core.events.fetchOperation': 'Получение списка событий',
  'core.events.notFound': 'Событий, удовлетворяющих условиям, не найдено!',
  'core.events.filter.title': 'Фильтр событий',
  'core.events.filter.location': 'Все $t(entity.sites) и $t(entity.tanks)',
  'core.events.filter.user': 'Все пользователи',
  'core.events.filter.scope': 'Операции',
  'core.events.filter.clear': 'Очистить фильтры',
  'core.events.deletedSubject.tank': '$t(entity.tank) удален',
  'core.events.deletedSubject.site': '$t(entity.site) удален',
  'core.events.deletedSubject.virtualSite': '$t(entity.virtualSite) удален',
  'core.events.deletedSubject.thermooximeter': 'Термооксиметр удален',
  'core.events.deletedSubject.stock': 'Склад удален',
  'core.events.table.timestamp': 'Время',
  'core.events.table.subject': 'Объект',
  'core.events.table.type': 'Операция',
  'core.events.table.description': 'Описание',
  'core.events.table.user': 'Пользователь',
  'core.events.description.overflowText': 'еще...',
  'core.events.description.catchReason': 'причина вылова',
  'core.events.description.movingReason': 'причина пересадки',
  'core.events.description.seedingReason': 'причина зарыбления',
  'core.events.description.mortalityReason': 'причина отхода',
  'core.events.description.fishWeightReason': 'причина изменения навески',
  'core.events.description.degreeday': 'градусодни',
  'core.events.description.comment': 'Комментарий',
  'core.events.description.biomass': 'биомасса',
  'core.events.description.feedRatio': 'КК',
  'core.events.description.medicine': 'Препарат',
  'core.events.description.fromTank': '$t(entity.tank) (из)',
  'core.events.description.toTank': '$t(entity.tank) (в)',
  'core.events.description.temperatureMap': 'температурная карта',
  'core.events.description.fromStock': 'со склада',
  'core.events.description.toStock': 'на склад',
  'core.events.types.fishWeight.insert': 'вес. ср. навески',
  'core.events.types.fishWeight.update': 'вес. ср. навески',
  'core.events.types.fishWeight.delete': 'вес. ср. навески',
  'core.events.types.fishMoving.insert': 'пересадка',
  'core.events.types.fishMoving.update': 'пересадка',
  'core.events.types.fishMoving.delete': 'пересадка',
  'core.events.types.fishMortality.insert': 'отход',
  'core.events.types.fishMortality.update': 'отход',
  'core.events.types.fishMortality.delete': 'отход',
  'core.events.types.fishCatch.insert': 'вылов',
  'core.events.types.fishCatch.update': 'вылов',
  'core.events.types.fishCatch.delete': 'вылов',
  'core.events.types.fishSeeding.insert': 'зарыбление',
  'core.events.types.fishSeeding.update': 'зарыбление',
  'core.events.types.fishSeeding.delete': 'зарыбление',
  'core.events.types.waterTemperature.insert': 'температура',
  'core.events.types.waterTemperature.update': 'температура',
  'core.events.types.waterTemperature.delete': 'температура',
  'core.events.types.waterNitrogen.insert': 'азот',
  'core.events.types.waterNitrogen.update': 'азот',
  'core.events.types.waterNitrogen.delete': 'азот',
  'core.events.types.waterOxygen.insert': 'кислород',
  'core.events.types.waterOxygen.update': 'кислород',
  'core.events.types.waterOxygen.delete': 'кислород',
  'core.events.types.waterNo2.insert': 'нитриты',
  'core.events.types.waterNo2.update': 'нитриты',
  'core.events.types.waterNo2.delete': 'нитриты',
  'core.events.types.waterNo3.insert': 'нитраты',
  'core.events.types.waterNo3.update': 'нитраты',
  'core.events.types.waterNo3.delete': 'нитраты',
  'core.events.types.waterNh4.insert': 'аммоний',
  'core.events.types.waterNh4.update': 'аммоний',
  'core.events.types.waterNh4.delete': 'аммоний',
  'core.events.types.waterPh.insert': 'водород',
  'core.events.types.waterPh.update': 'водород',
  'core.events.types.waterPh.delete': 'водород',
  'core.events.types.degreeday.insert': 'градусодни',
  'core.events.types.degreeday.update': 'градусодни',
  'core.events.types.degreeday.delete': 'градусодни',
  'core.events.types.feeding.insert': 'кормление',
  'core.events.types.feeding.update': 'кормление',
  'core.events.types.feeding.delete': 'кормление',
  'core.events.types.addSite': 'добавлен $t(entity.site)',
  'core.events.types.removeSite': 'удален $t(entity.site)',
  'core.events.types.addVirtualSite': 'добавлен $t(entity.virtualSite)',
  'core.events.types.removeVirtualSite': 'удален $t(entity.virtualSite)',
  'core.events.types.addTank': 'добавлен $t(entity.tank)',
  'core.events.types.removeTank': 'удален $t(entity.tank)',
  'core.events.types.relocateTank': 'перемещен $t(entity.tank)',
  'core.events.types.addTankToVirtualSites': 'установлена привязка $t(entity.tank) - $t(entity.virtualSites)',
  'core.events.types.removeTankFromVirtualSites': 'снята привязка $t(entity.tank) - $t(entity.virtualSites)',
  'core.events.types.resetTank': 'очистка',
  'core.events.types.revertResetTank': 'отмена очистки',
  'core.events.types.addProductionPlan': 'добавлен план',
  'core.events.types.updateProductionPlan': 'обновлен план',
  'core.events.types.removeProductionPlan': 'удален план',
  'core.events.types.addProductionPlanCorrection': 'добавлена корректировка плана',
  'core.events.types.removeProductionPlanCorrection': 'удалена корректировка плана',
  'core.events.types.setSiteDensity': 'задание плотности посадки',
  'core.events.types.setSiteDegreeday': 'задание градусодней',
  'core.events.types.setSiteTemperatureMap': 'редактирование температурной карты',
  'core.events.types.enableThermooximeter': 'включение',
  'core.events.types.disableThermooximeter': 'выключение',
  'core.events.types.setThermooximeterLocation': 'место',
  'core.events.types.setThermooximeterTanks': 'привязка',
  'core.events.types.setThermooximeterDepth': 'глубина',
  'core.events.types.insertThermooximeterWorkRule': 'новое правило',
  'core.events.types.updateThermooximeterWorkRule': 'редактирование правила',
  'core.events.types.deleteThermooximeterWorkRule': 'удаление правила',
  'core.events.types.createStock': 'добавление склада',
  'core.events.types.updateStock': 'редактирование склада',
  'core.events.types.deleteStock': 'удаление склада',
  'core.events.types.addStockIncome': 'добавление позиции на склад',
  'core.events.types.addStockOutcome': 'списание позиции со склада',
  'core.events.types.addStockTransfer': 'перемещение позиции',
  'core.events.types.updateStockTransfer': 'редактирование движения',
  'core.events.types.deleteStockTransfer': 'удаление движения',
  'core.events.scopes.water': 'Вода',
  'core.events.scopes.fish': 'Рыба',
  'core.events.scopes.mortality': 'Отход',
  'core.events.scopes.feeding': 'Кормление',
  'core.events.scopes.factorymap': 'Карта',
  'core.events.scopes.supervisor': 'Планы',
  'core.events.scopes.stocks': 'Склад',
  'core.events.scopes.iot': 'Устройства',
  'core.events.hasComment': 'Есть комментарий',
  'core.events.insert.fishWeight': 'Добавлен показатель: вес средней навески',
  'core.events.insert.moving': 'Добавлен показатель: перемещение рыбы',
  'core.events.insert.mortality': 'Добавлен показатель: отход рыбы',
  'core.events.insert.catch': 'Добавлен показатель: вылов рыбы',
  'core.events.insert.seeding': 'Добавлен показатель: зарыбление',
  'core.events.insert.temperature': 'Добавлен показатель: температура',
  'core.events.insert.oxygen': 'Добавлен показатель: кислород',
  'core.events.insert.feeding': 'Добавлен показатель: кормление',
  'core.events.update.fishWeight': 'Обновлен показатель: вес средней навески',
  'core.events.update.moving': 'Обновлен показатель: перемещение рыбы',
  'core.events.update.mortality': 'Обновлен показатель: отход рыбы',
  'core.events.update.catch': 'Обновлен показатель: вылов рыбы',
  'core.events.update.seeding': 'Обновлен показатель: зарыбление',
  'core.events.update.temperature': 'Обновлен показатель: температура',
  'core.events.update.oxygen': 'Обновлен показатель: кислород',
  'core.events.update.feeding': 'Обновлен показатель: кормление',
  'core.events.update.fishChange': 'Изменение рыбы',
  'core.events.update.tanks': 'Обновление данных',
  'core.events.delete.fishWeight': 'Удален показатель: вес средней навески',
  'core.events.delete.moving': 'Удален показатель: перемещение рыбы',
  'core.events.delete.mortality': 'Удален показатель: отход рыбы',
  'core.events.delete.catch': 'Удален показатель: вылов рыбы',
  'core.events.delete.seeding': 'Удален показатель: зарыбление',
  'core.events.delete.temperature': 'Удален показатель: температура',
  'core.events.delete.oxygen': 'Удален показатель: кислород',
  'core.events.delete.feeding': 'Удален показатель: кормление',
  'core.registries.title': 'Справочник',
  'core.registries.feedProducers': 'Корма',
  'core.registries.feedingPlans': 'Планы кормлений',
  'core.registries.medicines': 'Препараты',
  'core.registries.commonReasons': 'Причины операций',
  'core.registries.source.user': 'используемые',
  'core.registries.source.system': 'справочник',
  'core.feedingPlans.title': 'Планы кормления',
  'core.feedingPlans.userFeedingPlans': 'Используемые планы кормления',
  'core.feedingPlans.systemFeedingPlans': 'Справочник планов кормления',
  'core.feedingPlans.fetchFeedingPlansOperation': 'Получение планов кормления',
  'core.feedingPlans.fetchSystemFeedingPlansOperation': 'Получение справочника планов кормления',
  'core.feedingPlans.copySystemFeedingPlanOperation': 'Копирование плана кормления',
  'core.feedingPlans.feedingPlanCopySuccess': 'План кормления скопирован',
  'core.feedingPlans.feedingPlansNotFound': 'Планы кормления отсутствуют',
  'core.feedingPlans.createFeedingPlanOperation': 'Создание плана кормления',
  'core.feedingPlans.updateFeedingPlanOperation': 'Редактирование плана кормления',
  'core.feedingPlans.deleteFeedingPlanOperation': 'Удаление плана кормления',
  'core.feedingPlans.deleteFeedingPlanSuccess': 'План кормления удалён',
  'core.feedingPlans.addFeedingPlan': 'Добавить план',
  'core.feedingPlans.deleteConfirmText': 'План будет удален. Продолжить?',
  'core.feedingPlans.makeCopyConfirmText': 'План будет скопирован в $t(entity.factory). Продолжить?',
  'core.feedingPlans.makeCopy': 'Скопировать',
  'core.feedingPlans.isCopied': 'скопирован',
  'core.feedingPlans.copyToUserPlans': 'Скопировать в используемые',
  'core.feedingPlans.columns.name': 'Наименование',
  'core.feedingPlans.formTitle': 'План кормления',
  'core.feedingPlans.form.name': 'Наименование',
  'core.feedingPlans.form.startDatePlaceholder': 'с даты',
  'core.feedingPlans.form.endDatePlaceholder': 'по дату',
  'core.feedingPlans.form.addPeriod': 'Добавить период',
  'core.feedingPlans.form.deletePeriod': 'Удалить период',
  'core.feedingPlans.form.addRule': 'Добавить правило',
  'core.feedingPlans.form.deleteRule': 'Удалить правило',
  'core.feedingPlans.validation.requiredPeriod': 'выберите период',
  'core.feedingPlans.validation.requiredAtLeastOnePeriod': 'добавьте как минимум 1 период',
  'core.feedingPlans.validation.requiredAtLeastOneRule': 'добавьте как минимум 1 правило',
  'core.feedingPlans.validation.requiredFeedProducer': 'выберите корм',
  'core.feedingPlans.validation.requiredFeedingHandbook': 'выберите таблицу кормления',
  'core.feedingPlans.origin.label': 'Источник',
  'core.feedingPlans.origin.user': 'Пользователь',
  'core.feedingPlans.origin.system': 'Справочник',
  'core.feedRation.ration': 'Рацион',
  'core.feedRation.download': 'Скачать',
  'core.feedRation.preview': 'Просмотр',
  'core.feedRation.buildPdf': 'Собрать PDF',
  'core.feedRation.form.activity': 'Активность',
  'core.feedRation.form.fishBiomass': 'Биомасса',
  'core.feedRation.form.taraUnit': 'Ед. изм.',
  'core.feedRation.form.feedingCounter': 'Число кормл.',
  'core.feedRation.form.feedingNorm': 'Норма кормления',
  'core.feedRation.units.kg': 'кг',
  'core.feedRation.units.25kg': '25кг',
  'core.feedRation.report.titleWithDate': 'Рацион {{date}}',
  'core.feedRation.report.pageOfPages': '{{curPage}} из {{pageCount}}',
  'core.feedRation.report.siteWithName': '$t(entity.site): {{name}}',
  'core.feedRation.report.tankWithCount': '$t(entity.tank), {{count}}шт',
  'core.feedRation.report.biomass': 'Биомасса, кг',
  'core.feedRation.report.feed': 'Корм',
  'core.feedRation.report.feedingCount': 'Число\nкормл.',
  'core.feedRation.report.unit': 'Ед. изм.',
  'core.feedRation.report.norm': 'Норма кормления',
  'core.feedRation.report.dayNorm': 'Сут. норма для T°C',
  'core.feedRation.report.activity': 'Активность,\n%',
  'core.feedRation.report.temperature': 'T°C',
  'core.feedRation.report.total': 'Итого',
  'core.preferences.factory': 'Параметры',
  'core.preferences.savePreferencesOperation': 'Сохранение настроек',
  'core.preferences.fetchPreferencesOperation': 'Получение настроек',
  'core.preferences.preferencesSaved': 'Настройки сохранены',
  'core.preferences.entities.label': 'Терминология',
  'core.preferences.entities.single': 'ед.ч.',
  'core.preferences.entities.plural': 'мн.ч.',
  'core.preferences.entities.factory': 'Хозяйство',
  'core.preferences.entities.factories': 'Хозяйства',
  'core.preferences.entities.virtualSite': 'Виртуальный участок',
  'core.preferences.entities.virtualSites': 'Виртуальные участки',
  'core.preferences.entities.site': 'Участок',
  'core.preferences.entities.sites': 'Участки',
  'core.preferences.entities.tank': 'Садок',
  'core.preferences.entities.tanks': 'Садки',
  'core.preferences.waterIndicators.label': 'Показатели воды',
  'core.preferences.organization.label': 'Организация',
  'core.preferences.units': 'Единицы измерений',
  'core.preferences.fishWeightUnit.label': 'Навеска',
  'core.preferences.fishWeightUnit.options.gramsPerPiece': 'г/шт',
  'core.preferences.fishWeightUnit.options.kilogramsPerPiece': 'кг/шт',
  'core.preferences.strategies': 'Алгоритмы',
  'core.preferences.feedingTemperatureStrategy.label': 'Модель выбора температуры при кормлении',
  'core.preferences.feedingTemperatureStrategy.options.closest': 'Ближайшее значение',
  'core.preferences.feedingTemperatureStrategy.options.left': 'Левое значение',
  'core.preferences.feedingFishWeightStrategy.label': 'Модель выбора навески при кормлении',
  'core.preferences.feedingFishWeightStrategy.options.closest': 'Ближайшее значение',
  'core.preferences.feedingFishWeightStrategy.options.left': 'Левое значение',
  'core.preferences.thresholds.label': 'Границы нормы',
  'core.preferences.thresholds.temperature': 'Границы нормы температуры',
  'core.preferences.thresholds.oxygen': 'Границы нормы кислорода',
  'core.preferences.thresholds.ph': 'Границы нормы водорода',
  'core.preferences.thresholds.nitrogen': 'Границы нормы азота',
  'core.preferences.thresholds.nh4': 'Границы нормы аммония',
  'core.preferences.thresholds.no2': 'Границы нормы нитритов',
  'core.preferences.thresholds.no3': 'Границы нормы нитратов',
  'core.preferences.productionPlanStrategy.label': 'Модель производственных планов',
  'core.preferences.productionPlanStrategy.options.basic': 'Базовая',
  'core.preferences.productionPlanStrategy.options.loisto': 'Лоисто',
  'core.preferences.reports': 'Отчёты',
  'core.preferences.emailReport.sendReport': 'Отправлять отчёт',
  'core.preferences.emailReport.intervals.day': 'в конце дня',
  'core.preferences.emailReport.intervals.week': 'в конце недели',
  'core.preferences.emailReport.intervals.month': 'в конце месяца',
  'core.preferences.reportSignature.label': 'Подпись отчётов',
  'core.preferences.others': 'Прочее',
  'core.preferences.userSalesFromDate.label': 'Продажи с',
  'core.preferences.userFactoryBrief.tanks': '$t(entity.tanks), формирующие сводку $t(entity.factory)',
  'core.preferences.userFactoryBrief.tanksPlaceholder': 'Выберите $t(entity.tanks)',
  'core.preferences.briefDeltaDaysCount.label': 'Изменения в сводке за последние',
  'core.preferences.briefDeltaDaysCount.days': 'дней',
  'core.reports.title': 'Отчёты',
  'core.reports.factoryReport.title': 'Полный отчёт',
  'core.reports.factoryReport.formTitle': 'Полный отчёт за период',
  'core.reports.factoryBriefReport.title': 'Краткий отчёт',
  'core.reports.factoryBriefReport.formTitle': 'Краткий отчёт за период',
  'core.reports.fishSaleReport.title': 'Продажа рыбы',
  'core.reports.fishSaleReport.formTitle': 'Продажа рыбы за период',
  'core.reports.fishMovingReport.title': 'Пересадка рыбы',
  'core.reports.fishMovingReport.formTitle': 'Пересадка рыбы за период',
  'core.reports.feedingReport.title': 'Кормления',
  'core.reports.feedingReport.formTitle': 'Кормления за период',
  'core.reports.stocksStateReport.title': 'Остатки на складах',
  'core.reports.stocksStateReport.formTitle': 'Остатки на складах',
  'core.reports.stocksStateReport.stocks': 'Склады',
  'core.reports.stockFeedsReport.title': 'Движения кормов',
  'core.reports.stockMedicinesReport.title': 'Движения препаратов',
  'core.reports.month': 'Месяц',
  'core.reports.period': 'Период',
  'core.reports.date': 'Дата',
  'core.reports.downloadReportOperation': 'Загрузка отчёта',
  'core.reports.downloadReportByMonth': 'скачать отчёт за месяц',
  'core.reports.downloadReportInPeriod': 'скачать отчёт за период',
  'core.reports.downloadReport': 'Скачать отчёт',
  'core.reports.makeReport': 'Сформировать отчёт',
  'core.reports.makeReportToPreview': 'Сформируйте отчёт для просмотра',
  'core.reports.reportParamsHasChanged': 'Параметры для отчёта изменились',
  'core.acl.limitedAccess': 'ограниченный доступ',
  'core.acl.users.label': 'Пользователи',
  'core.acl.users.add': 'Добавить',
  'core.acl.users.jumpToRules': 'Перейти к правам',
  'core.acl.users.editUser': 'Редактировать пользователя',
  'core.acl.users.deleteUser': 'Удалить пользователя',
  'core.acl.users.addOperation': 'Добавление пользователя',
  'core.acl.users.updateOperation': 'Обновление пользователя',
  'core.acl.users.deleteOperation': 'Удаление пользователя',
  'core.acl.users.deleteConfirmText': 'Пользователь будет удален. Продолжить?',
  'core.acl.users.noRoleLabel': '---',
  'core.acl.users.form.title': 'Пользователь',
  'core.acl.users.form.name': 'Имя',
  'core.acl.users.form.login': 'Логин',
  'core.acl.users.form.password': 'Пароль',
  'core.acl.users.form.position': 'Должность',
  'core.acl.users.form.email': 'Эл. почта',
  'core.acl.roles.label': 'Роли',
  'core.acl.roles.add': 'Добавить',
  'core.acl.roles.jumpToRules': 'Перейти к правам',
  'core.acl.roles.editRole': 'Редактировать роль',
  'core.acl.roles.deleteRole': 'Удалить роль',
  'core.acl.roles.fetchOperation': 'Получение ролей',
  'core.acl.roles.addOperation': 'Добавление роли',
  'core.acl.roles.updateOperation': 'Обновление роли',
  'core.acl.roles.deleteConfirmText': 'Роль будет удалена. Продолжить?',
  'core.acl.roles.deleteOperation': 'Удаление роли',
  'core.acl.roles.form.title': 'Роль',
  'core.acl.roles.form.name': 'Наименование',
  'core.acl.roles.form.users': 'Пользователи',
  'core.acl.rules.label': 'Права доступа',
  'core.acl.rules.fetchRulesByOperation': 'Получение прав доступа по субъекту',
  'core.acl.rules.saveRuleOperation': 'Сохранение прав доступа',
  'core.acl.rules.copyRulesOperation': 'Копирование прав доступа',
  'core.acl.rules.subjectPlaceholder': 'Выберите субъект',
  'core.acl.rules.copySubjectRules': 'Скопировать права субъекта',
  'core.acl.rules.confirmLoseAclEditAccess': 'Доступ на редактирование прав доступа будет потерян. Продолжить?',
  'core.acl.rules.jumpToRules': 'перейти к правам',
  'core.acl.rules.subjects.user': 'Пользователь',
  'core.acl.rules.subjects.role': 'Роль',
  'core.acl.rules.fields.key': 'Объект',
  'core.acl.rules.fields.permission': 'Уровень доступа',
  'core.acl.rules.permissions.0': 'Нет доступа',
  'core.acl.rules.permissions.1': 'Чтение',
  'core.acl.rules.permissions.2': 'Редактирование',
  'core.acl.rules.permissions.3': 'Полный доступ',
  'core.unit.n/a': 'н/д',
  'core.unit.times': 'р.',
  'core.unit.percent': '%',
  'core.unit.meters': 'м',
  'core.unit.grams': 'г',
  'core.unit.kilograms': 'кг',
  'core.unit.tons': 'т',
  'core.unit.pieces': 'шт',
  'core.unit.piecesPerDay': 'шт/день',
  'core.unit.degreeday': '°C-дн',
  'core.unit.celsius': '°C',
  'core.unit.milligramsPerLiter': 'мг/л',
  'core.unit.tonsPerPiece': 'т/шт',
  'core.unit.kilogramsPerPiece': 'кг/шт',
  'core.unit.gramsPerPiece': 'г/шт',
  'core.unit.densityBiomass': 'кг/м³',
  'core.unit.densityAmount': 'шт/м²',
  'core.unit.unitPerDay': '{{unit}}/день',
  'core.months.0': 'Январь',
  'core.months.1': 'Февраль',
  'core.months.2': 'Март',
  'core.months.3': 'Апрель',
  'core.months.4': 'Май',
  'core.months.5': 'Июнь',
  'core.months.6': 'Июль',
  'core.months.7': 'Август',
  'core.months.8': 'Сентябрь',
  'core.months.9': 'Октябрь',
  'core.months.10': 'Ноябрь',
  'core.months.11': 'Декабрь',
  'core.validation.checkFormValues': 'проверьте данные формы',
  'core.validation.requiredField': 'поле обязательно для заполнения',
  'core.validation.shouldBeNumber': 'поле должно быть числом',
  'core.validation.shouldBeInteger': 'поле должно быть целым числом',
  'core.validation.shouldBePositive': 'поле должно быть положительным числом',
  'core.validation.shouldBeNotNegative': 'поле должно быть неотрицательным числом',
  'core.validation.shouldBeNotLessThan': 'значение должно быть не менее {{value}}',
  'core.validation.shouldBeLessThan': 'значение должно быть менее {{value}}',
  'core.validation.shouldBeNotGreaterThan': 'значение должно быть не более {{value}}',
  'core.validation.shouldBeGreaterThan': 'значение должно быть более {{value}}',
  'core.validation.requireUnit': 'укажите единицы измерения',
  'core.validation.requireBothThresholds': 'укажите обе границы',
  'core.validation.tanksShouldBeDifferent': '$t(entity.tanks) должны отличаться',
  'core.validation.fishWeightDiffNotGreaterThan': 'Разница в навесках не должна превышать {{diffInGrams}}г.',
  'core.validation.doesNotMatchFormat': 'Поле не соответствует формату',
  'core.validation.invalidEmail': 'Введите корректный email',
  'core.validation.periodDaysCountShouldBeGreaterThan': 'период должен содержать более {{count}}дн.',
  'core.errorPage.title': 'Ошибка',
  'core.errorPage.description': 'Что-то пошло не так...',
  'stocks.label': 'Склады',
  'stocks.router.stocks': 'Склады',
  'stocks.summary.factory': '$t(entity.factory)',
  'stocks.summary.fetchStocksFeedStateOperation': 'Получение состояния кормов на складах',
  'stocks.summary.fetchStocksMedicineStateOperation': 'Получение состояния препаратов на складах',
  'stocks.summary.state.positiveAmount': 'Количество, кг',
  'stocks.summary.state.negativeAmount': 'Отрицательные остатки, кг',
  'stocks.summary.state.total': 'Итого',
  'stocks.feedsReport.feedTransfers': 'движения кормов',
  'stocks.feedsReport.feedTransfersInPeriod': 'Движения кормов за период',
  'stocks.feedsReport.reportTitle': 'Наименование отчёта',
  'stocks.feedsReport.reportTitlePlaceholder': 'Движение кормов за {{month}} {{year}}',
  'stocks.feedsReport.reportSignature': 'Подпись отчёта',
  'stocks.feedsReport.reportSignaturePlaceholder': '{{userPos}} ___________________ / {{userName}}',
  'stocks.feedsReport.period': 'Период',
  'stocks.feedsReport.stocks': 'Склады',
  'stocks.feedsReport.feeds': 'Корма',
  'stocks.feedsReport.downloadReportInPeriod': 'скачать отчёт за период',
  'stocks.medicinesReport.medicineTransfers': 'движения препаратов',
  'stocks.medicinesReport.medicineTransfersInPeriod': 'Движения препаратов за период',
  'stocks.medicinesReport.reportTitle': 'Наименование отчёта',
  'stocks.medicinesReport.reportTitlePlaceholder': 'Движение препаратов за {{month}} {{year}}',
  'stocks.medicinesReport.reportSignature': 'Подпись отчёта',
  'stocks.medicinesReport.reportSignaturePlaceholder': '{{userPos}} ___________________ / {{userName}}',
  'stocks.medicinesReport.period': 'Период',
  'stocks.medicinesReport.stocks': 'Склады',
  'stocks.medicinesReport.medicines': 'Препараты',
  'stocks.medicinesReport.downloadReportInPeriod': 'скачать отчёт за период',
  'stocks.stock.fetchStocksOperation': 'Получение списка складов',
  'stocks.stock.createStockOperation': 'Добавление склада',
  'stocks.stock.updateStockOperation': 'Редактирование склада',
  'stocks.stock.deleteStockOperation': 'Удаление склада',
  'stocks.stock.fetchStockFeedStateOperation': 'Получение состояния кормов на складе',
  'stocks.stock.fetchStockMedicineStateOperation': 'Получение состояния препаратов на складе',
  'stocks.stock.deleteConfirmText': 'Склад будет удален. Продолжить?',
  'stocks.stock.add': 'Добавить склад',
  'stocks.stock.editStock': 'Редактировать склад',
  'stocks.stock.deleteStock': 'Удалить склад',
  'stocks.stock.single': 'Склад',
  'stocks.stock.allStocks': 'Все склады',
  'stocks.stock.tankStocks': '$t(entity.tank). Склады',
  'stocks.stock.restOfStocks': 'Остальные склады',
  'stocks.stock.tankIds': '$t(entity.sites) и $t(entity.tanks)',
  'stocks.stock.name': 'Наименование',
  'stocks.stock.comment': 'Комментарий',
  'stocks.stock.remainder': 'Остаток',
  'stocks.stock.tanksNotAssigned': '$t(entity.tanks) не указаны',
  'stocks.stock.stocksNotAssigned': 'Склады не указаны',
  'stocks.state.allFeeds': 'Все корма',
  'stocks.state.allMedicines': 'Все препараты',
  'stocks.state.feedProducer': 'Производитель',
  'stocks.state.medicine': 'Препарат',
  'stocks.state.amount': 'Количество',
  'stocks.state.amountInKg': 'Количество, кг',
  'stocks.state.total': 'Итого',
  'stocks.state.positiveTotal': 'Итого (остатки)',
  'stocks.state.negativeTotal': 'Итого (отрицательные остатки)',
  'stocks.feedState.allFeeds': 'Все корма',
  'stocks.feedState.feedProducer': 'Производитель',
  'stocks.feedState.amount': 'Количество',
  'stocks.feedState.amountInKg': 'Количество, кг',
  'stocks.feedState.total': 'Итого',
  'stocks.feedState.positiveTotal': 'Итого (остатки)',
  'stocks.feedState.negativeTotal': 'Итого (отрицательные остатки)',
  'stocks.category.allCategories': 'Все категории',
  'stocks.category.feeds': 'Корма',
  'stocks.category.medicines': 'Препараты',
  'stocks.transfers.label': 'Движения',
  'stocks.transfers.fetchTransfersOperation': 'Получение информации о движениях',
  'stocks.transfers.createStockTransferOperation': 'Внесение движений',
  'stocks.transfers.updateStockTransferOperation': 'Редактирование движений',
  'stocks.transfers.deleteStockTransferOperation': 'Удаление движений',
  'stocks.transfers.deleteConfirmText': 'Запись будет удалена. Продолжить?',
  'stocks.transfers.allFeeds': 'Все корма',
  'stocks.transfers.fields.timestamp': 'Дата и время',
  'stocks.transfers.fields.type': 'Тип операции',
  'stocks.transfers.fields.srcStock': 'Склад отправитель',
  'stocks.transfers.fields.destStock': 'Склад получатель',
  'stocks.transfers.fields.category': 'Категория',
  'stocks.transfers.fields.title': 'Наименование',
  'stocks.transfers.fields.amount': 'Количество',
  'stocks.transfers.fields.amountInKg': 'Количество, кг',
  'stocks.transfers.fields.reason': 'Причина',
  'stocks.transfers.fields.comment': 'Комментарий',
  'stocks.transfers.type.income': 'Поступление',
  'stocks.transfers.type.outcome': 'Списание',
  'stocks.transfers.type.transfer': 'Перемещение',
  'stocks.transfers.category.feed': 'корм',
  'stocks.transfers.category.medicine': 'препарат',
  'stocks.transfers.form.incomeLabel': 'Поступление на склад',
  'stocks.transfers.form.outcomeLabel': 'Списание со склада',
  'stocks.transfers.form.transferLabel': 'Перемещение между складами',
  'stocks.transfers.form.addItem': 'добавить',
  'stocks.transfers.form.fields.category': 'Категория',
  'stocks.transfers.form.fields.stock': 'Склад',
  'stocks.transfers.form.fields.srcStock': 'Склад отправитель',
  'stocks.transfers.form.fields.destStock': 'Склад получатель',
  'stocks.transfers.form.fields.comment': 'Комментарий',
  'stocks.transfers.form.fields.feedProducer': 'Корм',
  'stocks.transfers.form.fields.medicine': 'Препарат',
  'stocks.transfers.form.fields.amount': 'Количество',
  'stocks.transfers.form.fields.amountInKg': 'Количество, кг',
  'stocks.transfers.form.fields.outcomeAll': 'Списать остатки',
  'stocks.transfers.form.fields.outcomeReason': 'Причина списания',
  'stocks.transfers.validation.requireAtLeastOneItem': 'Добавьте хотя бы одну запись',
  'stocks.transfers.validation.missingFeedOnStock': 'корм "{{feed}}" на складе отсутствует',
  'stocks.transfers.validation.missingMedicineOnStock': 'препарат "{{medicine}}" на складе отсутствует',
  'stocks.transfers.validation.stockFeedAmountLeft': 'остаток корма "{{feed}}" на складе {{value}}',
  'stocks.transfers.validation.stockMedicineAmountLeft': 'остаток препарата "{{medicine}}" на складе {{value}}',
  'stocks.transfers.validation.stocksShouldBeDifferent': 'склады должны отличаться',
  'stocks.transfers.validation.feedNotAtStock': 'корм на складе отсутствует',
  'stocks.transfers.validation.medicineNotAtStock': 'препарат на складе отсутствует',
  'stocks.transfers.validation.feedAmountAtStock': 'остаток корма на складе {{value}}',
  'stocks.transfers.validation.medicineAmountAtStock': 'остаток препарата на складе {{value}}',
  'supervisor.label': 'Планы',
  'supervisor.router.supervisor': 'Планы',
  'supervisor.router.productionPlans': 'Производственный план',
  'supervisor.sitesPicker.placeholder': 'Укажите $t(entity.sites)',
  'supervisor.settings.title': 'Настройки',
  'supervisor.temperatureMap.label': 'Температурная карта',
  'supervisor.temperatureMap.filePicker.placeholder': 'выберите файл',
  'supervisor.temperatureMap.filePicker.tip': 'scv файл с температурой воды за год',
  'supervisor.temperatureMap.filePicker.format': 'формат строки',
  'supervisor.temperatureMap.export': 'Выгрузить температурную карту',
  'supervisor.temperatureMap.fetchErrorText': 'При загрузке произошла ошибка',
  'supervisor.temperatureMap.saveErrorText': 'При сохранении произошла ошибка',
  'supervisor.temperatureMap.saveSuccessText': 'Успешно сохранена',
  'supervisor.temperatureMap.withValue': 'Значение указано',
  'supervisor.temperatureMap.withoutValue': 'Значение не указано',
  'supervisor.temperatureMap.validation.rowDoesNotMatchFormat': 'строка "{{row}}" не соответствует формату',
  'supervisor.temperatureMap.validation.missingDate': 'пропущена дата {{date}}',
  'supervisor.density.label': 'Плотность посадки',
  'supervisor.density.fetchErrorText': 'При загрузке произошла ошибка',
  'supervisor.density.saveErrorText': 'При сохранении произошла ошибка',
  'supervisor.density.saveSuccessText': 'Успешно сохранена',
  'supervisor.density.withValue': 'Значение указано',
  'supervisor.density.withoutValue': 'Значение не указано',
  'supervisor.degreeday.label': 'Градусодни',
  'supervisor.degreeday.fetchErrorText': 'При загрузке произошла ошибка',
  'supervisor.degreeday.saveErrorText': 'При сохранении произошла ошибка',
  'supervisor.degreeday.saveSuccessText': 'Успешно сохранены',
  'supervisor.degreeday.withValue': 'Значение указано',
  'supervisor.degreeday.withoutValue': 'Значение не указано',
  'supervisor.feedingBrief.label': 'Сводка по кормам',
  'supervisor.feedingBrief.period': 'Период',
  'supervisor.feedingBrief.plan': 'План',
  'supervisor.feedingBrief.fact': 'Факт',
  'supervisor.feedingBrief.feedProducer': 'Корм',
  'supervisor.feedingBrief.total': 'Итого',
  'supervisor.productionBrief.title': 'Показатели на день',
  'supervisor.productionBrief.brief': 'Сводка',
  'supervisor.productionBrief.syncDate': 'Синхронизовать дату',
  'supervisor.productionBrief.addCorrection': 'Добавить корректировку',
  'supervisor.productionBrief.hint.metric': 'показатель',
  'supervisor.productionBrief.hint.value': 'факт / план',
  'supervisor.production.fact': 'факт',
  'supervisor.production.estimation': 'расчет',
  'supervisor.production.plan': 'план',
  'supervisor.production.metrics.fishAmount': 'Рыба',
  'supervisor.production.metrics.fishBiomass': 'Биомасса',
  'supervisor.production.metrics.fishBiomassByDay': 'Биомасса (за день)',
  'supervisor.production.metrics.fishWeight': 'Навеска',
  'supervisor.production.metrics.densityBiomass': 'Плотность биомассы',
  'supervisor.production.metrics.densityAmount': 'Плотность рыбы',
  'supervisor.production.metrics.seeding': 'Зарыбление',
  'supervisor.production.metrics.mortality': 'Отход',
  'supervisor.production.metrics.mortalityByDay': 'Отход (за день)',
  'supervisor.production.metrics.catch': 'Вылов',
  'supervisor.production.metrics.accCatch': 'Вылов',
  'supervisor.production.metrics.catchByDay': 'Вылов (за день)',
  'supervisor.production.metrics.catchBiomass': 'Биомасса вылова',
  'supervisor.production.metrics.accCatchBiomass': 'Биомасса вылова',
  'supervisor.production.metrics.catchBiomassByDay': 'Биомасса вылова (за день)',
  'supervisor.production.metrics.accMortality': 'Отход (накопленный)',
  'supervisor.production.metrics.mortalityBiomass': 'Биомасса отхода',
  'supervisor.production.metrics.accMortalityBiomass': 'Биомасса отхода',
  'supervisor.production.metrics.mortalityBiomassByDay': 'Биомасса отхода (за день)',
  'supervisor.production.metrics.moving': 'Пересадка',
  'supervisor.production.metrics.feeding': 'Кормление',
  'supervisor.production.metrics.feedRatio': 'Кормовой коэффициент',
  'supervisor.production.metrics.feedingByDay': 'Кормление (за день)',
  'supervisor.production.metrics.accFeeding': 'Кормление (накопленное)',
  'supervisor.production.metrics.degreeday': 'Градусодни',
  'supervisor.production.metrics.temperature': 'Температура',
  'supervisor.production.metrics.oxygen': 'Кислород',
  'supervisor.production.columns.timestamp': 'Дата',
  'supervisor.production.columns.type': 'Показатель',
  'supervisor.production.columns.temperature': 'T',
  'supervisor.production.columns.fishAmount': 'Рыба',
  'supervisor.production.columns.fishBiomass': 'Биомасса',
  'supervisor.production.columns.fishWeight': 'Навеска',
  'supervisor.production.columns.mortality': 'Отход',
  'supervisor.production.columns.catch': 'Вылов',
  'supervisor.production.columns.feedProducer': 'Корм',
  'supervisor.production.columns.feedingHandbook': 'Таблица кормления',
  'supervisor.production.columns.feeding': 'Кормление',
  'supervisor.production.columns.byDay': '(за день)',
  'supervisor.production.tableMode': 'Режим',
  'supervisor.production.tableModes.absolute': 'все значения абсолютные',
  'supervisor.production.tableModes.relativeByPlan': 'относительно плана',
  'supervisor.production.tableModes.relativeByFact': 'относительно факта',
  'supervisor.productionPlans.title': 'Производственный план',
  'supervisor.productionPlans.label': 'Производственные планы',
  'supervisor.productionPlans.period.decade': 'Декада',
  'supervisor.productionPlans.period.month': 'Месяц',
  'supervisor.productionPlans.period.maxSeason': 'Макс. сезон',
  'supervisor.productionPlans.loading.fetch': 'Загрузка данных',
  'supervisor.productionPlans.loading.fetchByFactory': 'Загрузка данных: {{name}}',
  'supervisor.productionPlans.loading.getFact': 'Обработка данных',
  'supervisor.productionPlans.loading.getEstimation': 'Обработка данных',
  'supervisor.productionPlans.loading.getPlan': 'Обработка данных',
  'supervisor.productionPlans.loading.fetchPlanMetrics': 'Загрузка плановых значений',
  'supervisor.productionPlans.loading.fetchEstimationMetrics': 'Загрузка расчетных значений',
  'supervisor.productionPlans.loading.fetchFactMetrics': 'Загрузка фактических значений',
  'supervisor.productionPlans.plansNotFoundInPeriod': 'Выбранный период не содержит производственных планов',
  'supervisor.productionPlans.fetchFishMetricsOperation': 'Получение показателей рыбы',
  'supervisor.productionPlans.fetchManyOperation': 'Получение производственных планов',
  'supervisor.productionPlans.fetchOneOperation': 'Получение производственного плана',
  'supervisor.productionPlans.createOperation': 'Создание производственного плана',
  'supervisor.productionPlans.updateOperation': 'Редактирование производственного плана',
  'supervisor.productionPlans.deleteOneOperation': 'Удаление производственного плана',
  'supervisor.productionPlans.save': 'Сохранить',
  'supervisor.productionPlans.create': 'Создать',
  'supervisor.productionPlans.addPlan': 'Создать план',
  'supervisor.productionPlans.editPlan': 'Редактировать план',
  'supervisor.productionPlans.editPlans': 'Редактировать планы',
  'supervisor.productionPlans.deletePlan': 'Удалить план',
  'supervisor.productionPlans.deleteConfirmText': 'Производственный план будет удален. Продолжить?',
  'supervisor.productionPlans.saveErrorText': 'Не удалось сохранить план',
  'supervisor.productionPlans.fetchPlanErrorText': 'Не удалось загрузить производственный план',
  'supervisor.productionPlans.updateErrorText': 'Не удалось обновить производственный план',
  'supervisor.productionPlans.deleteErrorText': 'Не удалось удалить производственный план',
  'supervisor.productionPlans.marker.excessOfDensity': 'Превышение плотности посадки',
  'supervisor.productionPlans.marker.changeOfFeedProducer': 'Смена корма',
  'supervisor.productionPlans.marker.accomplishmentOfDegreeday': 'Достижение градусодней',
  'supervisor.productionPlans.plansList': 'Список планов',
  'supervisor.productionPlans.correction.plural': 'Корректировки',
  'supervisor.productionPlans.correction.inTank': 'Корректировка плана в',
  'supervisor.productionPlans.correction.label': 'Корректировка производственного плана',
  'supervisor.productionPlans.correction.add': 'Добавить',
  'supervisor.productionPlans.correction.saveErrorText': 'При сохранении произошла ошибка',
  'supervisor.productionPlans.correction.deleteErrorText': 'При удалении произошла ошибка',
  'supervisor.productionPlans.correction.newProductionPlan': 'Новый производственный план',
  'supervisor.productionPlans.correction.deleteConfirmText': 'Корректировка будет удалена. Продолжить?',
  'supervisor.productionPlans.correction.createPlanInMovingTankConfirmText': 'Будет создан производственный план в "{{tankLocation}}". Продолжить?',
  'supervisor.productionPlans.correction.form.type': 'Форма',
  'supervisor.productionPlans.correction.form.date': 'Дата',
  'supervisor.productionPlans.correction.form.planValue': 'План',
  'supervisor.productionPlans.correction.form.correctedValue': 'Корректировка',
  'supervisor.productionPlans.correction.form.reason': 'Причина',
  'supervisor.productionPlans.correction.form.catchReason': 'Причина вылова',
  'supervisor.productionPlans.correction.form.catchAmount': 'Вылов',
  'supervisor.productionPlans.correction.form.catchBiomass': 'Биомасса вылова',
  'supervisor.productionPlans.correction.form.seedingAmount': 'Зарыбление',
  'supervisor.productionPlans.correction.form.seedingBiomass': 'Биомасса зарыбления',
  'supervisor.productionPlans.correction.form.movingAmount': 'Пересадка',
  'supervisor.productionPlans.correction.form.movingBiomass': 'Биомасса пересадки',
  'supervisor.productionPlans.correction.form.fromTank': '$t(entity.tank) (из)',
  'supervisor.productionPlans.correction.form.toTank': '$t(entity.tank) (в)',
  'supervisor.productionPlans.correction.form.period': 'Период',
  'supervisor.productionPlans.correction.form.sinceDate': 'С',
  'supervisor.productionPlans.correction.form.untilDate': 'По',
  'supervisor.productionPlans.correction.form.mortalityAmount': 'Отход',
  'supervisor.productionPlans.correction.form.mortalityBiomass': 'Биомасса отхода',
  'supervisor.productionPlans.correction.form.mortalityPerDayByPlan': 'План (день)',
  'supervisor.productionPlans.correction.form.mortalityInPeriodByPlan': 'План (период, шт)',
  'supervisor.productionPlans.correction.form.mortalityPercentInPeriodByPlan': 'План (период, %)',
  'supervisor.productionPlans.correction.form.newMortalityPerDay': 'Корректировка (день)',
  'supervisor.productionPlans.correction.form.newMortalityByPeriod': 'Корректировка (период, шт)',
  'supervisor.productionPlans.correction.form.newMortalityPercentByPeriod': 'Корректировка (период, %)',
  'supervisor.productionPlans.correction.form.catchReasonPlaceholder': 'Укажите причину вылова',
  'supervisor.productionPlans.correction.form.feedProducerByPlan': 'План (корм)',
  'supervisor.productionPlans.correction.form.newFeedProducer': 'Корректировка (корм)',
  'supervisor.productionPlans.correction.form.feedingHandbookByPlan': 'План (таблица)',
  'supervisor.productionPlans.correction.form.newFeedingHandbook': 'Корректировка (таблица)',
  'supervisor.productionPlans.correction.types.fishAmount': 'Рыба',
  'supervisor.productionPlans.correction.types.fishBiomass': 'Биомасса',
  'supervisor.productionPlans.correction.types.mortality': 'Отход',
  'supervisor.productionPlans.correction.types.feeding': 'Кормление',
  'supervisor.productionPlans.correction.types.fishWeight': 'Навеска',
  'supervisor.productionPlans.correction.fishWeightReasons.feedRatio': 'КК',
  'supervisor.productionPlans.correction.fishWeightReasons.feedingAmount': 'Объем корма',
  'supervisor.productionPlans.correction.feedingReasons.feedProducer': 'Корм',
  'supervisor.productionPlans.correction.feedingReasons.feedingAmount': 'Объем корма',
  'supervisor.productionPlans.correction.reasons.fishWeight': 'Навеска',
  'supervisor.productionPlans.correction.reasons.seeding': 'Зарыбление',
  'supervisor.productionPlans.correction.reasons.catch': 'Вылов',
  'supervisor.productionPlans.correction.reasons.moving': 'Пересадка',
  'supervisor.productionPlans.correction.reasons.mortality': 'Отход',
  'supervisor.productionPlans.correction.movingGroups.valid': 'подходящие',
  'supervisor.productionPlans.correction.movingGroups.validShouldCreatePlan': 'подходящие без плана',
  'supervisor.productionPlans.correction.movingGroups.invalidByFishAmount': 'недостаточно рыб',
  'supervisor.productionPlans.correction.movingGroups.invalidByMissingPlan': 'нет плана',
  'supervisor.productionPlans.correction.movingGroups.invalidByFishWeight': 'разная навеска',
  'supervisor.productionPlans.form.formTitle': 'Производственный план',
  'supervisor.productionPlans.form.newPlan': 'Новый производственный план',
  'supervisor.productionPlans.form.editPlan': 'Редактирование производственного плана',
  'supervisor.productionPlans.form.seasonDates': 'Даты сезона',
  'supervisor.productionPlans.form.seasonStart': 'Начало сезона',
  'supervisor.productionPlans.form.seasonEnd': 'Конец сезона',
  'supervisor.productionPlans.form.tankId': '$t(entity.tank)',
  'supervisor.productionPlans.form.feedingPlanId': 'План кормления',
  'supervisor.productionPlans.form.mortalityPercent': 'Отход',
  'supervisor.productionPlans.form.mortalityPercentPlaceholder': 'Введите число',
  'supervisor.productionPlans.form.initialFishAmount': 'Количество рыбы на начало сезона',
  'supervisor.productionPlans.form.initialFishAmountPlaceholder': 'Введите число',
  'supervisor.productionPlans.form.initialFishWeight': 'Навеска в начале сезона',
  'supervisor.productionPlans.form.initialFishWeightPlaceholder': 'Введите число',
  'supervisor.productionPlans.form.initialFishBiomass': 'Биомасса рыбы на начало сезона',
  'supervisor.productionPlans.form.initialFishBiomassPlaceholder': 'Введите число',
  'supervisor.productionPlans.form.initialDegreeday': 'Градусодни на начало сезона',
  'supervisor.productionPlans.form.initialDegreedayPlaceholder': 'Введите число',
  'supervisor.productionPlans.form.templatesPlaceholder': 'Скопировать из садка',
  'supervisor.productionPlans.validation.requireDates': 'даты должны быть заполнены',
  'supervisor.productionPlans.validation.valuesShouldBeDifferent': 'значения не должны совпадать',
  'supervisor.productionPlans.validation.shouldBeSuitable': 'поля должны быть согласованы',
  'supervisor.productionPlans.validation.requireAtLeasOneDate': 'заполните хотя бы одну дату',
  'supervisor.productionPlans.validation.datesShouldBeInOnePlan': 'даты должны быть в рамках одного плана',
  'supervisor.productionPlans.validation.dateShouldBeInPlan': 'дата должна быть в рамках плана',
  'supervisor.productionPlans.validation.dateHasSuchCorrection': 'в эту дату уже указана такая корректировка',
  'supervisor.productionPlans.validation.tankIsInvalid': '$t(entity.tank) не подходит',
  'supervisor.productionPlans.validation.feedProducerNotSuitable': 'корм не подходит',
  'supervisor.productionPlans.validation.feedProducersShouldBeDifferent': 'корма должны отличаться',
  'supervisor.productionPlans.validation.feedingHandbookNotSuitable': 'таблица не подходит',
  'supervisor.productionPlans.validation.feedingHandbooksShouldBeDifferent': 'таблицы должны отличаться',
  'supervisor.acl.messages.notEnoughPermissionToView': 'недостаточно прав для просмотра',
  'iot.label': 'Оборудование',
  'iot.saveBtn': 'Сохранить',
  'iot.addBtn': 'Добавить оборудование',
  'iot.filter.type': 'Тип',
  'iot.filter.status': 'Статус',
  'iot.filter.tankIds': 'Привязка',
  'iot.cancelBtn': 'Отмена',
  'iot.validationErrorText': 'Проверьте правильность заполнения полей!',
  'iot.requiredFieldValidation': 'обязательное поле',
  'iot.zoomInTitle': 'Приблизить',
  'iot.zoomOutTitle': 'Отдалить',
  'iot.search': 'Поиск',
  'iot.datePickerStartLabel': 'С',
  'iot.datePickerEndLabel': 'По',
  'iot.valueNotSet': 'не установлено',
  'iot.period.today': 'Этот день',
  'iot.period.week': 'Эта неделя',
  'iot.period.month': 'Этот месяц',
  'iot.period.year': 'Этот год',
  'iot.statuses.warning': 'Внимание',
  'iot.router.home': 'Устройства',
  'iot.router.thermooximeter': 'Устройства',
  'iot.router.thermooximeters': 'Устройства',
  'iot.router.shippingList': 'Перевозки',
  'iot.router.shippingDetails': 'Детали перевозки',
  'iot.fishShipping.fetchShippingListOperation': 'Получение списка перевозок',
  'iot.fishShipping.fetchShippingDetailsOperation': 'Получение деталей перевозки',
  'iot.thermoOximeter.fetchAllOperation': 'Получение списка термооксиметров',
  'iot.thermoOximeter.fetchOneOperation': 'Получение термооксиметра',
  'iot.thermoOximeter.updateOperation': 'Обновление данных термооксиметра',
  'iot.thermoOximeter.countLabel': 'Термооксиметры {{count}} шт.',
  'iot.thermoOximeter.label': 'Термооксиметр',
  'iot.thermoOximeter.deviceId': '№ прибора',
  'iot.thermoOximeter.battery': 'Батарея',
  'iot.thermoOximeter.poweredOn': 'Вкл.',
  'iot.thermoOximeter.poweredOff': 'Выкл.',
  'iot.thermoOximeter.infoName': 'Имя',
  'iot.thermoOximeter.infoId': 'ID',
  'iot.thermoOximeter.statusTitle': 'Статус',
  'iot.thermoOximeter.statusActive': 'Работает',
  'iot.thermoOximeter.statusFaulty': 'На ремонте',
  'iot.thermoOximeter.statusDeactivate': 'Вышел из строя',
  'iot.thermoOximeter.statusInStock': 'На складе',
  'iot.thermoOximeter.locationTitle': 'Место установки',
  'iot.thermoOximeter.seeOnMap': 'Посмотреть на карте',
  'iot.thermoOximeter.bindTankTitle': 'Привязка',
  'iot.thermoOximeter.changeBindTank': 'Изменить привязку',
  'iot.thermoOximeter.locationLat': 'Широта',
  'iot.thermoOximeter.locationLng': 'Долгота',
  'iot.thermoOximeter.changeLocation': 'Изменить координаты',
  'iot.thermoOximeter.historyTypes.error': 'Ошибки',
  'iot.thermoOximeter.historyTypes.measurement': 'Измерения',
  'iot.thermoOximeter.historyTypes.status': 'Статус',
  'iot.thermoOximeter.historyTypes.location': 'Место установки',
  'iot.thermoOximeter.historyTypes.rule': 'Правила',
  'iot.thermoOximeter.historyTypes.permission': 'Права доступа',
  'iot.thermoOximeter.historyTypes.planWork': 'Работы',
  'iot.thermoOximeter.getWorkRulesOperation': 'Получение правил',
  'iot.thermoOximeter.createWorkRule': 'Добавить правило',
  'iot.thermoOximeter.messageSuccessUpdate': 'Данные термооксиметра обновлены',
  'iot.thermoOximeter.createWorkRuleOperation': 'Создание правила',
  'iot.thermoOximeter.messageSuccessCreateWorkRule': 'Правило для термооксиметра добавлено',
  'iot.thermoOximeter.updateWorkRuleOperation': 'Обновление правила',
  'iot.thermoOximeter.messageSuccessUpdateWorkRule': 'Правило для термооксиметра обновлено',
  'iot.thermoOximeter.removeWorkRuleOperation': 'Удаление правила',
  'iot.thermoOximeter.messageSuccessRemoveWorkRule': 'Правило для термооксиметра удалено',
  'iot.thermoOximeter.messageConfirmRemoveWorkRule': 'Вы действительно хотите удалить правило?',
  'iot.thermoOximeter.messageErrorUpdateWorkRule': 'Ошибка обновления правила',
  'iot.thermoOximeter.messageErrorRemoveWorkRule': 'Ошибка удаления правила',
  'iot.thermoOximeter.getHistoryOperation': 'Получение истории',
  'iot.thermoOximeter.getIndicatorsOperation': 'Получение измерений',
  'iot.thermoOximeter.editWorkRule': 'Редактировать правило',
  'iot.thermoOximeter.removeWorkRule': 'Удалить правило',
  'iot.thermoOximeter.historyTitle': 'История',
  'iot.thermoOximeter.timeWorked': 'Отработанное время',
  'iot.thermoOximeter.scheduleWork': 'Запланировать работы',
  'iot.thermoOximeter.operatingModeTitle': 'Режим работы',
  'iot.thermoOximeter.operatingModeRulesTitle': 'Правила',
  'iot.thermoOximeter.operatingModeAuto': 'Автоматический',
  'iot.thermoOximeter.operatingModeManual': 'Ручной',
  'iot.thermoOximeter.addRule': 'Добавить правило',
  'iot.thermoOximeter.activeRule': 'Активное',
  'iot.thermoOximeter.notActiveRule': 'Неактивное',
  'iot.thermoOximeter.enableRule': 'Включить правило',
  'iot.thermoOximeter.disableRule': 'Выключить правило',
  'iot.thermoOximeter.indicatorsTitle': 'Измерения',
  'iot.thermoOximeter.takeMeasurement': 'Выполнить измерение',
  'iot.thermoOximeter.addEvent': 'Добавить событие',
  'iot.thermoOximeter.depthTitle': 'Глубина',
  'iot.thermoOximeter.depthUnit': 'м.',
  'iot.thermoOximeter.changeDepth': 'Изменить глубину',
  'iot.thermoOximeter.ownerTitle': 'Ответственный',
  'iot.thermoOximeter.changeOwner': 'Изменить ответственного',
  'iot.thermoOximeter.notFound': 'Данных не найдено...',
  'iot.thermoOximeter.mapReturnBtn': 'В центр',
  'plans.label': 'Планы',
  'plans.router.plans': 'Планы',
  'plans.fetchManyOperation': 'Получение списка задач',
  'plans.changeTaskStatusOperation': 'Изменение статуса задачи',
  'plans.saveOperation': 'Сохранение задачи',
  'plans.deleteOperation': 'Удаление задачи',
  'plans.sitesAndTanks': '$t(entity.sites) и $t(entity.tanks)'
}
