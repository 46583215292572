<template>
  <section class="shipping-details">
    <div v-if="isLoading">
      Загрузка...
    </div>
    <div v-else-if="details">
      <div v-for="(item, i) in tanksDetails" :key="i" class="tank-details">
        <div class="tank-identifier">
          {{ item.name }}
        </div>
        <div class="tank-water-chart">
          <shipping-tank-water-chart v-if="item.points.length" :parameters="item.parameters" :points="item.points" />
          <span v-else>данных нет</span>
        </div>
      </div>
    </div>
    <div v-else>
      Ошибка: {{ error }}
    </div>
  </section>
</template>

<script>
import { fetchShippingDetails } from '@/modules/iot/api/fishShipping'
import { parseError } from '@/utils/request'
import { formatTimestamp } from '@/utils/date'
import ShippingTankWaterChart from '@/modules/iot/components/ShippingTankWaterChart.vue'

export default {
  components: {
    ShippingTankWaterChart
  },
  props: {
    shippingId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      error: null,
      details: null
    }
  },
  computed: {
    tanksDetails () {
      return this.details?.tanks.map((tank, i) => {
        const points = this.details.points.filter(point => point.thermooximeterDeviceId === tank.thermooximeterDeviceId)
        points.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        return {
          name: `бак ${i + 1}`,
          parameters: tank.parameters,
          points
        }
      })
    }
  },
  created () {
    this.fetchDetails()
  },
  methods: {
    formatTimestamp,
    async fetchDetails () {
      this.isLoading = true
      try {
        const { data } = await fetchShippingDetails(this.shippingId)
        this.details = data
      } catch (e) {
        this.details = null
        this.error = parseError(e)
        this.$notify.error({
          title: this.$t('iot.fishShipping.fetchShippingDetailsOperation'),
          message: this.error
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.shipping-details {
  .tank-details {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    font-size: 16px;
    margin: 1em 0;
    .tank-identifier {
      width: 100px;
      text-align: end;
      font-weight: 500;
    }
    .tank-water-chart {
      flex-grow: 1;
    }
  }
}
</style>
