export const roundByPercent = (n, p = 0.01) => {
  const log10 = Math.floor(Math.log10(Math.abs(n * p) || 1))
  const precision = log10 < 0 ? Math.abs(log10) : 0
  return +n.toFixed(precision)
}

export const formatNumber = (value, precision = 0, valueOnError = 'n/a') => {
  if (value == null) {
    return valueOnError
  }
  let num = Number(value)
  if (isNaN(num)) {
    return valueOnError
  }
  let p = precision ? 1 / Math.pow(10, precision) : 0.01
  if (Math.abs(num) < 1) {
    p /= 10
  }
  num = roundByPercent(num, p)
  return num.toLocaleString('ru-RU')
}

export const formatNumberPercentRound = (value, percent = 0.01, valueOnError = 'n/a') => {
  if (value == null) {
    return valueOnError
  }
  let num = Number(value)
  if (isNaN(num)) {
    return valueOnError
  }
  if (percent !== null) {
    num = roundByPercent(value, percent)
  }
  return num.toLocaleString('ru-RU')
}
