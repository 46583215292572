<template>
  <div id="app">
    <transport-login v-if="!user" />
    <shipping-list v-else />
  </div>
</template>

<script>
import TransportLogin from '@/modules/core/pages/TransportLogin'
import ShippingList from '@/modules/iot/pages/ShippingList'

export default {
  components: {
    TransportLogin,
    ShippingList
  },
  computed: {
    user () {
      return this.$store.state.auth.me
    }
  }
}
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
}
</style>
