import { startOfYear } from '@/utils/date'

export const getSalesPeriod = preferences => {
  const end = new Date()
  return preferences?.userSalesFromUse
    ? [preferences.userSalesFromDate, end.toISOString()]
    : [startOfYear(end).toISOString(), end.toISOString()]
}

export const getBriefDeltaDate = (preferences, date) => {
  const deltaDaysCount = preferences?.briefDeltaDaysCount || 0
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - deltaDaysCount + 1
  )
}

export const getWaterIndicators = preferences => {
  const usedTypes = preferences?.waterIndicators || {}
  return Object.keys(usedTypes).filter(type => usedTypes[type])
}
