import Vue from 'vue'
import { ls, KEYS } from '@/utils/store'
import { PERIOD } from '@/modules/core/use/datePicker'
import { setEntities } from '@/i18next'
import { getNavbarItems } from '@/router'

export default {
  namespaced: true,
  state: {
    isReady: false,
    loadings: [],
    navbarItems: [],
    period: PERIOD.month().map(date => date.toISOString()),
    isEmptySitesVisible: ls.get(KEYS.IS_EMPTY_SITES_VISIBLE)
  },
  getters: {
    periodStart: state => new Date(state.period[0]).toISOString(),
    periodEnd: state => new Date(state.period[1]).toISOString(),
    yearsInPeriod: state => {
      const [lYear, rYear] = state.period.map(v => new Date(v).getFullYear())
      const yearsInPeriod = []
      for (let year = lYear; year <= rYear; year++) {
        yearsInPeriod.push(year)
      }
      return yearsInPeriod
    }
  },
  mutations: {
    SET_IS_READY: (state, isReady) => {
      state.isReady = isReady
    },
    ADD_LOADING_ITEM: (state, name) => {
      state.loadings.push(name)
    },
    REMOVE_LOADING_ITEM: (state, name) => {
      Vue.set(state, 'loadings', state.loadings.filter(item => item !== name))
    },
    SET_NAVBAR_ITEMS: (state, items) => {
      state.navbarItems = items
    },
    CHANGE_PERIOD: (state, period) => {
      Vue.set(state, 'period', period)
    },
    SET_IS_EMPTY_SITES_VISIBLE: (state, isVisible) => {
      ls.set(KEYS.IS_EMPTY_SITES_VISIBLE, isVisible)
      Vue.set(state, 'isEmptySitesVisible', isVisible)
    }
  },

  actions: {
    async bootstrap ({ rootState, dispatch, commit }) {
      commit('ADD_LOADING_ITEM', 'fetchPreferences')
      const preferences = await dispatch('preferences/fetchPreferences', null, { root: true })
      commit('REMOVE_LOADING_ITEM', 'fetchPreferences')
      setEntities(preferences.entities)
      commit('SET_NAVBAR_ITEMS', getNavbarItems())

      commit('ADD_LOADING_ITEM', 'fetchFactoryContent')
      dispatch('factory/fetchFactoryContent', null, { root: true })
      commit('REMOVE_LOADING_ITEM', 'fetchFactoryContent')

      commit('ADD_LOADING_ITEM', 'fetchFactoryMetrics')
      await Promise.all([
        dispatch('factory/metrics/fetchByTanks', null, { root: true }),
        dispatch('factory/metrics/fetchSalesByTanks', null, { root: true }),
        dispatch('factory/metrics/fetchPrevByTanks', null, { root: true }),
        dispatch('factory/metrics/fetchPrevSalesByTanks', null, { root: true })
      ])
      commit('REMOVE_LOADING_ITEM', 'fetchFactoryMetrics')

      commit('ADD_LOADING_ITEM', 'fetchFeeds')
      await Promise.all([
        dispatch('core/feedProducers/throttledFetchFeedProducers', null, { root: true }),
        dispatch('core/feedingPlans/fetchFeedingPlans', null, { root: true })
      ])
      commit('REMOVE_LOADING_ITEM', 'fetchFeeds')

      commit('ADD_LOADING_ITEM', 'fetchMedicines')
      await dispatch('core/medicines/fetchMedicines', null, { root: true })
      commit('REMOVE_LOADING_ITEM', 'fetchMedicines')

      commit('ADD_LOADING_ITEM', 'fetchCommonReasons')
      await dispatch('core/commonReasons/fetchCommonReasons', null, { root: true })
      commit('REMOVE_LOADING_ITEM', 'fetchCommonReasons')

      commit('ADD_LOADING_ITEM', 'fetchUsers')
      await dispatch('acl/fetchUsers', null, { root: true })
      await dispatch('acl/fetchRoles', null, { root: true })
      commit('REMOVE_LOADING_ITEM', 'fetchUsers')

      commit('ADD_LOADING_ITEM', 'fetchStocks')
      await dispatch('stocks/fetchStocks', null, { root: true })
      commit('REMOVE_LOADING_ITEM', 'fetchStocks')

      if (rootState.auth.me?.id) {
        await dispatch('core/notifications/loadUserNotificationsCounter', null, { root: true })
      }
      commit('SET_IS_READY', true)
    }
  }
}
