<template>
  <div class="shipping-tank-water-chart">
    <div class="plot">
      <v-chart
        ref="chart"
        :option="option"
        :update-options="updateOptions"
        :autoresize="true"
        @legendselectchanged="onLegendSelectChanged"
      />
    </div>
    <div class="boundaries-container">
      <boundaries-button
        v-for="type in indicatorTypes"
        v-show="option.legend.selected[type]"
        :key="type"
        :label="boundaryLabels[type]"
        :status="boundariesStatus[type]"
        :active="markedSeries === type"
        @click="toggleMarkedSeries(type)"
      />
    </div>
  </div>
</template>

<script>
import { uniq } from 'lodash'
import VChart from 'vue-echarts'
import { use as echartsUse } from 'echarts/core'
import { LineChart } from 'echarts/charts'
import { GridComponent, LegendComponent, MarkAreaComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { formatNumber } from '@/modules/core/use/formatters'
import { chartColors } from '@/modules/core/use/colorPalettes'
import { d_MMM_FORMAT, d_MMM_yyyy_HH_mm_FORMAT, formatDate } from '@/utils/date'

echartsUse([
  LineChart,
  TooltipComponent,
  LegendComponent,
  MarkAreaComponent,
  GridComponent,
  CanvasRenderer
])

const BoundariesButton = {
  render (h) {
    const { status, active, label } = this.$attrs
    return h('el-button', {
      attrs: {
        size: 'medium',
        plain: true,
        type: status
      },
      class: [
        'boundaries-btn',
        'boundaries-btn--temperature',
        `boundaries-btn--${status}`,
        active && 'boundaries-btn--active'
      ],
      on: this.$listeners
    }, [label])
  }
}

export default {
  components: {
    VChart,
    BoundariesButton
  },

  props: {
    parameters: {
      type: Array,
      required: true
    },
    points: {
      type: Array,
      required: true
    }
  },

  data () {
    const colors = {
      oxygen: chartColors[0],
      temperature: chartColors[1],
      success: '#E8FCEA',
      warning: '#FCF4E8',
      danger: '#FCE8EB'
    }

    const h = this.$createElement
    const sub = node => h('span', {
      style: {
        'font-size': '.7em',
        'vertical-align': 'sub'
      }
    }, [node])
    const boundaryLabels = {
      temperature: h('span', ['T', this.$t('core.unit.celsius')]),
      oxygen: h('span', ['O', sub(2)])
    }

    return {
      colors,
      markedSeries: null,
      boundaryLabels,
      markAreas: {},
      symbolSizeBase: 4,
      symbolSizeBig: 6,
      option: this.makeChartOption(),
      updateOptions: {
        notMerge: true,
        lazyUpdate: true
      },
      indicatorTypes: [
        'temperature',
        'oxygen'
      ]
    }
  },

  computed: {
    dataByTypes () {
      return this.indicatorTypes.reduce((acc, type) => {
        acc[type] = this.points
          .map(point => ([
            point.timestamp,
              point.parameters.find(param => param.type === type)?.value
          ]))
          .filter(item => typeof item[1] !== 'undefined')
        return acc
      }, {})
    },
    boundariesStatus () {
      return this.indicatorTypes.reduce((acc, type) => {
        const param = this.parameters.find(param => param.type === type)
        const data = this.dataByTypes[type]
        if (!param || data.length === 0) {
          acc[type] = 'info'
          return acc
        }

        if (data.some(item => item[1] < param.min || param.max < item[1])) {
          acc[type] = 'danger'
          return acc
        }

        if (data.some(item => item[1] < param.mid1 || param.mid2 < item[1])) {
          acc[type] = 'warning'
          return acc
        }

        acc[type] = 'success'
        return acc
      }, {})
    }
  },

  async mounted () {
    this.setupMarkAreas()
    this.setupChart()
  },

  methods: {
    setupChart () {
      const option = this.makeChartOption()

      const gridIndex = 0
      option.grid[gridIndex].show = true
      option.xAxis[gridIndex].show = true
      option.xAxis[gridIndex].data = uniq(this.points.map(item => item.timestamp))

      for (const type of this.indicatorTypes) {
        const yAxisIndex = option.yAxis.findIndex(
          axis => axis.name === this.$t(`core.metrics.${type}`)
        )
        if (yAxisIndex === -1) {
          console.warning(`yAxis for ${type} not found`)
          return
        }

        option.yAxis[yAxisIndex].show = true

        option.legend.selected[type] = true
        option.legend.data.push(type)

        option.color.push(this.colors[type])

        const series = {
          type: 'line',
          symbolSize: this.symbolSizeBase,
          data: this.dataByTypes[type]
        }

        series.xAxisIndex = gridIndex
        series.yAxisIndex = yAxisIndex
        series.name = type
        option.series.push(series)
      }

      this.resetMarkedSeries()
      this.updateMarkedArea(option)
      this.option = option
    },
    makeChartOption () {
      return {
        color: [],
        legend: {
          formatter: this.seriesNameFormatter,
          selected: {},
          data: []
        },
        grid: [
          {
            left: 80,
            right: 80,
            top: 60,
            height: 220,
            show: false,
            backgroundColor: '#f7f7f7'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'solid'
            }
          },
          formatter: this.tooltipFormatter
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all'
            }
          ]
        },
        xAxis: [
          {
            gridIndex: 0,
            show: false,
            type: 'category',
            boundaryGap: false,
            splitNumber: 3,
            axisLabel: {
              formatter: this.formatAxisDate
            },
            data: []
          }
        ],
        yAxis: [
          {
            gridIndex: 0,
            show: false,
            name: this.$t('core.metrics.temperature'),
            nameLocation: 'end',
            nameTextStyle: {
              fontSize: 12,
              align: 'right'
            },
            axisLine: {
              lineStyle: {
                width: 2
              }
            },
            type: 'value',
            splitLine: {
              show: false
            },
            boundaryGap: ['3%', '3%'],
            axisLabel: {
              formatter: `{value} ${this.$t('core.unit.celsius')}`
            },
            position: 'left'
          },
          {
            gridIndex: 0,
            show: false,
            name: this.$t('core.metrics.oxygen'),
            nameLocation: 'end',
            nameTextStyle: {
              fontSize: 12,
              align: 'left'
            },
            axisLine: {
              lineStyle: {
                width: 2
              }
            },
            type: 'value',
            splitLine: {
              show: false
            },
            boundaryGap: ['3%', '3%'],
            axisLabel: {
              formatter: `{value} ${this.$t('core.unit.milligramsPerLiter')}`
            },
            position: 'right'
          }
        ],
        series: []
      }
    },
    setupMarkAreas () {
      this.markAreas = this.indicatorTypes.reduce((acc, type) => {
        const param = this.parameters.find(param => param.type === type)
        if (param) {
          acc[type] = this.makeBoundariesMarkArea({
            success: [param.mid1, param.mid2],
            warning: [param.min, param.max],
            danger: [-Infinity, Infinity]
          })
        }
        return acc
      }, {})
    },
    makeBoundariesMarkArea (boundaries) {
      return {
        silent: true,
        data: [
          [
            {
              itemStyle: {
                color: this.colors.danger
              },
              yAxis: boundaries.danger[0]
            },
            {
              yAxis: boundaries.danger[1]
            }
          ],
          [
            {
              itemStyle: {
                color: this.colors.warning
              },
              yAxis: boundaries.warning[0]
            },
            {
              yAxis: boundaries.warning[1]
            }
          ],
          [
            {
              itemStyle: {
                color: this.colors.success
              },
              yAxis: boundaries.success[0]
            },
            {
              yAxis: boundaries.success[1]
            }
          ]
        ]
      }
    },
    resetMarkedSeries () {
      this.markedSeries = null
    },
    seriesNameFormatter (name) {
      return this.$t(`core.metrics.${name}`)
    },
    tooltipFormatter (params) {
      if (!params.length) {
        return ''
      }
      const time = this.formatTooltipDate(params[0].value[0])
      let tooltip = `<span>${time}</span><br>`
      for (var i = 0; i < params.length; i++) {
        tooltip += `${params[i].marker}
          <span>${this.seriesNameFormatter(params[i].seriesName)}:</span>
          <span>${formatNumber(params[i].value[1], 2)}</span>
          <br>`
      }
      return tooltip
    },
    formatTooltipDate (value) {
      return formatDate(new Date(value), d_MMM_yyyy_HH_mm_FORMAT)
    },
    formatAxisDate (value) {
      return formatDate(new Date(value), d_MMM_FORMAT)
    },
    onLegendSelectChanged ({ selected }) {
      const option = this.option
      option.legend.selected = selected
      const selectedSeries = []
      option.series.forEach(series => {
        const isVisible = selected[series.name]
        option.yAxis[series.yAxisIndex].show = isVisible
        if (isVisible) {
          selectedSeries.push(series.name)
        }
      })
      if (selectedSeries.length === 1) {
        this.markedSeries = selectedSeries[0]
      } else {
        this.resetMarkedSeries()
      }
      this.updateMarkedArea(option)
      this.option = option
    },
    toggleMarkedSeries (name) {
      const option = this.option
      this.markedSeries = name === this.markedSeries ? null : name
      this.updateMarkedArea(option)
      this.option = option
    },
    updateMarkedArea (option) {
      const markArea = this.markAreas[this.markedSeries]
      for (const series of option.series) {
        if (series.name === this.markedSeries) {
          series.markArea = markArea
          series.symbolSize = this.symbolSizeBig
        } else {
          delete series.markArea
          series.symbolSize = this.symbolSizeBase
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.shipping-tank-water-chart {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;

  .plot {
    height: 300px;
    min-width: 300px;
    flex-grow: 1;
  }

  .boundaries-container {
    .el-button.boundaries-btn {
      display: block;
      margin: 10px 0 0 0;
      width: 80px;
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      &--active {
        border-color: var(--color-primary-500);
      }
    }
  }
}
</style>
