import Vue from 'vue'
import '@/i18next'
import store from '@/store'
// import router from '@/router'
import '@/plugins/element.js'
import App from '@/TransportApp.vue'

Vue.config.productionTip = false

import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.scss'

new Vue({
  // router,
  store,
  render: h => h(App)
}).$mount('#app')
