<template>
  <div class="page-layout">
    <div class="page-toolbar">
      <div class="page-breadcrumbs">
        {{ $t('iot.router.shippingList') }}
      </div>
    </div>

    <section>
      <div v-if="isLoading">
        Загрузка...
      </div>
      <div v-else-if="error">
        Ошибка: {{ error }}
      </div>
      <div v-else>
        <shipping-card v-for="shipping in shippingList" :key="shipping.id" :shipping="shipping" />
        <div v-if="shippingList.length === 0">
          список пока пуст
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { fetchShippings } from '@/modules/iot/api/fishShipping'
import { parseError } from '@/utils/request'
import { formatTimestamp } from '@/utils/date'
import ShippingCard from '@/modules/iot/components/ShippingCard'

export default {
  components: {
    ShippingCard
  },
  data () {
    return {
      isLoading: false,
      error: null,
      shippingList: []
    }
  },
  created () {
    // set mobile layout
    const meta = document.querySelector('meta[name="viewport"]')
    meta.content = 'initial-scale=1'
    this.fetchShippingList()
  },
  methods: {
    formatTimestamp,
    async fetchShippingList () {
      this.isLoading = true
      try {
        const { data } = await fetchShippings()
        this.shippingList = data
      } catch (e) {
        this.shippingList = []
        this.error = parseError(e)
        this.$notify.error({
          title: this.$t('iot.fishShipping.fetchShippingListOperation'),
          message: this.error
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.shipping-card {
  padding: .5em 1em;
  border: 1px solid var(--color-border);
  border-radius: .5em;
  pre {
    margin: 0;
    max-height: 300px;
    overflow: auto;
  }
}
.shipping-card + .shipping-card {
  margin-top: 1em;
}
</style>
