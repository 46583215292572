import { groupBy, chunk } from 'lodash'

export const mapTankWidth = 180

export const mapTankHeight = 180

export const generateFactoryMap = function (params) {
  const options = {
    tanks: [],
    startX: 10,
    startY: 10,
    columns: 5,
    ...params
  }
  const factoryMap = {}
  const columns = options.columns
  let y = options.startY
  const tanksBySite = groupBy(options.tanks, tank => tank.siteId)
  Object.keys(tanksBySite).forEach((siteId, siteIndex) => {
    const siteRows = chunk(tanksBySite[siteId], columns)
    siteRows.forEach((siteRow, siteRowIndex) => {
      let x = options.startX
      siteRow.forEach((tank, tankIndex) => {
        factoryMap[tank.id] = {
          x: x,
          y: y,
          siteId
        }
        x += mapTankWidth + 20
      })
      y += mapTankHeight + 20
    })
    y += 40
  })
  return factoryMap
}

export const makeTankCascaderOptions = function (factory, options) {
  const isTankDisabled = options?.isTankDisabled || (() => false)
  const comparator = options?.comparator || ((a, b) => a.disabled - b.disabled)
  const tankByIds = factory.tanks.reduce((acc, tank) => {
    acc[tank.id] = tank
    return acc
  }, {})
  return factory.sites.reduce((options, site) => {
    const tankOptions = site.tankIds
      .map(tankId => tankByIds[tankId])
      .filter(Boolean)
      .map(tank => {
        return {
          key: `site-${site.id}-tank-${tank.id}`,
          value: tank.id,
          label: tank.name,
          disabled: isTankDisabled(tank.id)
        }
      })
    tankOptions.sort(comparator)
    options.push({
      key: `site-${site.id}`,
      value: site.id,
      label: site.name,
      children: tankOptions,
      disabled: tankOptions.every(option => option.disabled)
    })
    options.sort(comparator)
    return options
  }, [])
}

export const getFactoryContentForUser = function (factory, acl) {
  const userFactory = {}

  const canReadTanks = {}
  factory.tanks.forEach(tank => {
    canReadTanks[tank.id] = acl.isAllowed(
      acl.P.R,
      acl.R.c(acl.R.Factory, acl.R.Tank(tank.id))
    )
  })
  userFactory.tanks = factory.tanks.filter(tank => canReadTanks[tank.id])

  userFactory.sites = factory.sites
    .map(site => ({
      ...site,
      tankIds: site.tankIds.filter(tankId => canReadTanks[tankId])
    }))
    .filter(site => site.tankIds.length ||
      acl.isAllowed(
        acl.P.R,
        acl.R.c(acl.R.Factory, acl.R.Site(site.id))
      )
    )

  userFactory.virtualSites = factory.virtualSites
    .map(site => ({
      ...site,
      tankIds: site.tankIds.filter(tankId => canReadTanks[tankId]),
      curTankIds: site.curTankIds.filter(tankId => canReadTanks[tankId])
    }))
    .filter(site => site.tankIds.length === 0 ||
      site.tankIds.some(tankId => canReadTanks[tankId])
    )

  userFactory.map = {
    ...factory.map,
    tanksLocation: {}
  }
  userFactory.tanks.forEach(tank => {
    userFactory.map.tanksLocation[tank.id] = factory.map.tanksLocation[tank.id]
  })

  return userFactory
}

export function getUnionTanksBySite (tankIds, factory) {
  const unionTanksBySite = {
    sites: [],
    tanks: [],
    partialSites: []
  }
  const tankSites = factory.tanks.reduce((acc, tank) => {
    acc[tank.id] = tank.siteId
    return acc
  }, {})
  const siteTanksCount = factory.tanks.reduce((acc, tank) => {
    if (acc[tank.siteId]) {
      acc[tank.siteId] += 1
    } else {
      acc[tank.siteId] = 1
    }
    return acc
  }, {})
  const tanksBySiteId = tankIds.reduce((acc, tankId) => {
    const siteId = tankSites[tankId]
    if (acc.hasOwnProperty(siteId)) {
      acc[siteId].push(tankId)
    } else {
      acc[siteId] = [tankId]
    }
    return acc
  }, {})

  for (const [siteId, siteTankIds] of Object.entries(tanksBySiteId)) {
    if (siteTankIds.length === siteTanksCount[siteId]) {
      unionTanksBySite.sites.push(siteId)
    } else {
      unionTanksBySite.tanks.push(...siteTankIds)
      unionTanksBySite.partialSites.push({
        siteId,
        tankIds: siteTankIds
      })
    }
  }

  return unionTanksBySite
}

export function sortFactory (factory) {
  const tanksCount = factory.tanks.length
  const weights = factory.sites.reduce((acc, site, siteIndex) => {
    const startIndex = tanksCount * siteIndex
    site.tankIds.map((tankId, tankIndex) => {
      acc[tankId] = startIndex + tankIndex
    })
    return acc
  }, {})
  factory.tanks.sort((a, b) => weights[a.id] - weights[b.id])
}
