<template>
  <div class="app-info-container">
    <button class="app-info-trigger" @click="infoDialogVisible = true" />
    <el-dialog
      :visible.sync="infoDialogVisible"
      class="app-info-dialog"
      width="680px"
    >
      <div slot="title" class="app-info-dialog__title">
        {{ $t('core.app.name') }}
      </div>

      <div class="app-info">
        <div class="app-info__content">
          <p class="description">
            {{ $t('core.app.description') }}
          </p>
          <p class="version">
            {{ $t('core.app.name') }}: {{ info.version }}
          </p>
          <div class="contacts">
            <div class="contact">
              <i class="mdi mdi-email contact-icon" />
              <a href="mailto:contact@fishweb.ru" class="link contact-link">
                contact@fishweb.ru
              </a>
            </div>
            <div class="contact">
              <i class="mdi mdi-at contact-icon" />
              <a href="http://www.fishweb.ru" target="_blank" class="link contact-link">
                www.fishweb.ru
              </a>
            </div>
          </div>
        </div>
        <div class="app-info__picture">
          <img src="/InfoCardPicture.png" alt="infocard">
        </div>
      </div>

      <div slot="footer" class="app-info-dialog__footer">
        {{ $t('core.app.copyrightWithYear', {year: info.year} ) }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from '@/config'
export default {
  name: 'ToolbarAppLogo',
  data () {
    return {
      infoDialogVisible: false,
      info: {
        version: config.VERSION,
        year: new Date().getFullYear()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-info-container{
  box-sizing: border-box;
  width: 58px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-600);
  overflow: hidden;
}

.app-info-trigger {
  --trigger-size: 42px;
  height: var(--trigger-size);
  width: var(--trigger-size);
  box-sizing: border-box;
  border: 2px solid #EBEEF5;
  border-radius: var(--trigger-size);
  padding: 0;
  text-align: center;
  color: var(--color-primary-600);

  background: url(/InfoCardPicture.png) no-repeat center center;
  background-size: 80%;
  background-color: white;
}

.app-info-trigger:hover {
  cursor: pointer;
  border-color: #2C2C2D;
}

.app-info-dialog {

  .app-info-dialog__title {
    color: var(--color-primary-600);
    display: block;
    text-align: center;
    padding-bottom: 20px;
    width: 100%;
    font-size: 22pt;
    font-weight: bold;
    border-bottom: 3px solid #EBEEF5;
  }
  .app-info {
    display: flex;
    width: 100%;

    .app-info__content {
      flex-grow: 1;

      .description {
        margin: 0 0 20px 0;
        color: rgb(106,106,106);
        font-weight: 600;
        font-size: 16px;
        text-decoration: none;
      }

      .version {
        margin: 0 0 20px 0;
        color: black;
        font-weight: normal;
        font-size: 16px;
        text-decoration: none;
      }

      .contacts {
        .contact {
          margin: 0 0 5px 0;
          display: flex;

          .contact-icon {
            font-size: 18px;
          }
          .contact-link {
            margin-left: 15px;
            font-weight: normal;
            font-size: 16px;
            text-decoration: none;
          }
        }
      }
    }

    .app-info__picture img {
      width: 210px;
      height: 210px;
    }
  }

  .app-info-dialog__footer {
    display: block;
    text-align: center;
    font-size: 14px;
    width: 100%;
  }
}
</style>
