export const tableau10 = [
  '#4E79A7',
  '#F2BE2B',
  '#E15759',
  '#76B7B2',
  '#59A14F',
  '#EDC948',
  '#807AA1',
  '#FF9DA7',
  '#9C755F',
  '#BAB0AC'
]

export const tableau20 = [
  '#4E79A7',
  '#A0CBE8',
  '#F28E2B',
  '#FFBE7D',
  '#E15759',
  '#FF9D9A',
  '#499894',
  '#86BCB6',
  '#59A14F',
  '#8CD17D',
  '#B6992D',
  '#F1CE63',
  '#B07AA1',
  '#D4A6C8',
  '#D37295',
  '#FABFD2',
  '#9D7660',
  '#D7B5A6',
  '#79706E',
  '#BAB0AC'
]

export const chartColors = [
  '#4E79A7',
  '#E15759',
  '#499894',
  '#FFBE7D',
  '#59A14F',
  '#B07AA1',
  '#B6992D',
  '#D37295',
  '#9D7660',
  '#79706E',
  '#5C6068',
  '#B3B7B8',
  '#A0CBE8',
  '#FF9D9A',
  '#86BCB6',
  '#F28E2B',
  '#8CD17D',
  '#D4A6C8',
  '#F1CE63',
  '#FABFD2',
  '#D7B5A6',
  '#BAB0AC',
  '#989CA3',
  '#D3D3D3'
]

export const seattleGrays = [
  '#767F8B',
  '#B3B7B8',
  '#5C6068',
  '#D3D3D3',
  '#989CA3'
]

export const millerStone = [
  '#4F6980',
  '#849DB1',
  '#A2CEAA',
  '#638B66',
  '#BFBB60',
  '#F47942',
  '#FBB04E',
  '#B66353',
  '#D7CE9F',
  '#B9AA97',
  '#7E756D'
]

export const superfishelStone = [
  '#6388B4',
  '#FFAE34',
  '#EF6F6A',
  '#8CC2CA',
  '#55AD89',
  '#C3BC3F',
  '#BB7693',
  '#BAA094',
  '#A9B5AE',
  '#767676'
]

export const rowHighlightColors = [
  '#ffd9ed',
  '#f2cece',
  '#DFD2C6',
  '#f2e9ce',
  '#fff1bf',
  '#E0F0CD',
  '#d2f2ce',
  '#cef2ef',
  '#cedff2',
  '#d4cef2'
]

export const siteColors = [
  '#D32F2F',
  '#C2185B',
  '#7B1FA2',
  '#512DA8',
  '#303F9F',
  '#1976D2',
  '#0288D1',
  '#0097A7',
  '#00796B',
  '#388E3C',
  '#689F38',
  '#AFB42B',
  '#FBC02D',
  '#FFA000',
  '#F57C00',
  '#E64A19',
  '#5D4037',
  '#616161',
  '#455A64'
]

export const tankColors = [
  '#ffd9ed',
  '#f2cece',
  '#DFD2C6',
  '#f2e9ce',
  '#fff1bf',
  '#E0F0CD',
  '#d2f2ce',
  '#cef2ef',
  '#cedff2',
  '#d4cef2',
  ''
]
