import { meanBy, sumBy } from 'lodash'
import { parseISO as parseDate } from '@/utils/date'

export const MAX_FISH_WEIGHT_DIFF_IN_KILOGRAMS = 0.3

export const hasFishWeightsSameGroup = function (...fishWeights) {
  const maxFishWeight = Math.max(...fishWeights)
  const minFishWeight = Math.min(...fishWeights)
  if (maxFishWeight - minFishWeight < MAX_FISH_WEIGHT_DIFF_IN_KILOGRAMS) {
    return true
  }
  return false
}

export const getWorstStatus = function (statuses) {
  const counter = {
    'success': 0,
    'warning': 0,
    'danger': 0
  }
  statuses.forEach(status => counter[status]++)
  return counter.danger > 0
    ? 'danger' : counter.warning > 0
      ? 'warning' : 'success'
}

export const normalizeValue = function (value, unit) {
  switch (unit) {
    case 'grams':
    case 'gramsPerPiece':
      return value / 1000
    case 'tons':
    case 'tonsPerPiece':
      return value * 1000
    default:
      return value
  }
}

export const normalizeUnit = function (unit) {
  switch (unit) {
    case 'grams':
    case 'tons':
      return 'kilograms'
    case 'gramsPerPiece':
    case 'tonsPerPiece':
      return 'kilogramsPerPiece'
    default:
      return unit
  }
}

export const getIndicatorStatus = function (i) {
  if (i === null || typeof i === 'undefined' || (!i && isNaN(i))) {
    return 'danger'
  }
  const STATUSES = ['success', 'warning', 'danger']
  return STATUSES.includes(i.status) ? i.status : 'info'
}

export const getIndicatorTimestampStatus = function (timestamp) {
  const DAY_IN_MS = 3600 * 24 * 1000
  const diffInMs = new Date() - new Date(timestamp)
  if (diffInMs < DAY_IN_MS) {
    return 'success'
  }
  if (diffInMs < 3 * DAY_IN_MS) {
    return 'warning'
  }
  return 'danger'
}

export const isAllowedEditIndicator = function (indicator, acl) {
  const tankIds = [indicator.tankId]
  if (indicator.type === 'moving') {
    tankIds.push(indicator.movingTankId)
  }
  return tankIds.every(tankId => acl.isAllowed(
    acl.P.W,
    acl.R.c(acl.R.Factory, acl.R.Tank(tankId))
  ))
}

export const isAllowedDeleteIndicator = function (indicator, acl) {
  const tankIds = [indicator.tankId]
  if (indicator.type === 'moving') {
    tankIds.push(indicator.movingTankId)
  }
  return tankIds.every(tankId => acl.isAllowed(
    acl.P.W,
    acl.R.c(acl.R.Factory, acl.R.Tank(tankId))
  ))
}

export const movingInputParams = {
  value: {
    precision: 0,
    step: 1,
    min: 1
  },
  fishBiomass: {
    precision: 3,
    step: 0.001,
    min: 0.001
  }
}

export const seedingInputParams = {
  value: {
    precision: 0,
    step: 1,
    min: 1
  },
  fishBiomass: {
    precision: 3,
    step: 0.001,
    min: 0.001
  }
}

export const mortalityInputParams = {
  value: {
    precision: 0,
    step: 1,
    min: 1
  },
  fishBiomass: {
    precision: 3,
    step: 0.001,
    min: 0.001
  }
}

export const catchInputParams = {
  value: {
    precision: 0,
    step: 1,
    min: 1
  },
  fishBiomass: {
    precision: 3,
    step: 0.001,
    min: 0.001
  }
}

export const feedingInputParams = {
  value: {
    precision: 2,
    step: 0.01,
    min: 0
  },
  valueByUnit: {
    grams: {
      strategyPrecision: 3,
      precision: 0,
      step: 1,
      min: 0
    },
    kilograms: {
      strategyPrecision: 1,
      precision: 2,
      step: 0.01,
      min: 0
    },
    tons: {
      strategyPrecision: 0,
      precision: 3,
      step: 0.001,
      min: 0
    }
  },
  feedRatio: {
    precision: 2,
    step: 0.01,
    min: 0
  },
  medicine: {
    grams: {
      min: 0,
      precision: 0,
      step: 1
    },
    kilograms: {
      min: 0,
      precision: 3,
      step: 0.001
    }
  }
}

export const fishWeightInputParams = {
  value: {
    precision: 3,
    step: 0.001,
    min: 0.001,
    max: 20
  },
  valueByUnit: {
    gramsPerPiece: {
      precision: 3,
      step: 0.001,
      min: 0.001,
      max: 20000
    },
    kilogramsPerPiece: {
      precision: 3,
      step: 0.001,
      min: 0.001,
      max: 20
    }
  }
}

export const oxygenInputParams = {
  precision: 2,
  step: 0.01,
  min: 0,
  max: 14
}

export const temperatureInputParams = {
  precision: 1,
  step: 0.1,
  min: 0,
  max: 35
}

export const nitrogenInputParams = {
  precision: 2,
  step: 0.01,
  min: 0,
  max: 2
}

export const no2InputParams = {
  precision: 3,
  step: 0.001,
  min: 0,
  max: 2
}

export const no3InputParams = {
  precision: 3,
  step: 0.1,
  min: 0,
  max: 50
}

export const nh4InputParams = {
  precision: 3,
  step: 0.001,
  min: 0,
  max: 2
}

export const phInputParams = {
  precision: 3,
  step: 0.001,
  min: 0,
  max: 14
}

export const degreedayInputParams = {
  precision: 2,
  step: 0.01,
  min: 0
}

export const inputParams = {
  moving: movingInputParams,
  seeding: seedingInputParams,
  mortality: mortalityInputParams,
  catch: catchInputParams,
  feeding: feedingInputParams,
  fishWeight: fishWeightInputParams,
  oxygen: oxygenInputParams,
  temperature: temperatureInputParams,
  nitrogen: nitrogenInputParams,
  no2: no2InputParams,
  no3: no3InputParams,
  nh4: nh4InputParams,
  ph: phInputParams,
  degreeday: degreedayInputParams
}

export const syncFishMetrics = (metrics, trigger) => {
  const syncedMetrics = {
    fishAmount: metrics.fishAmount,
    fishBiomass: metrics.fishBiomass,
    fishWeight: metrics.fishWeight
  }
  const { fishAmount: fa, fishBiomass: fb, fishWeight: fw } = metrics
  if (trigger === 'fishBiomass' && fb === 0) {
    syncedMetrics.fishAmount = 0
    syncedMetrics.fishWeight = 0
  } else if (trigger !== 'fishBiomass' && fa > 0 && fw > 0) {
    syncedMetrics.fishBiomass = fw * fa
  } else if (trigger !== 'fishBiomass' && fa === 0 && fw === 0) {
    syncedMetrics.fishBiomass = 0
  } else if (trigger === 'fishWeight' && fw === 0) {
    syncedMetrics.fishAmount = 0
    syncedMetrics.fishBiomass = 0
  } else if (trigger !== 'fishWeight' && fa > 0 && fb > 0) {
    syncedMetrics.fishWeight = fb / fa
  } else if (trigger !== 'fishWeight' && fa === 0 && fb === 0) {
    syncedMetrics.fishWeight = 0
  } else if (trigger === 'fishAmount' && fa === 0) {
    syncedMetrics.fishWeight = 0
    syncedMetrics.fishBiomass = 0
  } else if (trigger !== 'fishAmount' && fb > 0 && fw > 0) {
    syncedMetrics.fishAmount = Math.floor(fb / fw)
  } else if (trigger !== 'fishAmount' && fb === 0 && fw === 0) {
    syncedMetrics.fishAmount = 0
  }
  return syncedMetrics
}

const defaultSale = Object.freeze({
  amount: 0,
  biomass: 0
})

export const makeTankMetrics = (data) => {
  data = data || {}
  const metrics = {
    fishChange: data.fishChange ?? null,
    oxygen: data.oxygen ?? null,
    temperature: data.temperature ?? null,
    nitrogen: data.nitrogen ?? null,
    no2: data.no2 ?? null,
    no3: data.no3 ?? null,
    nh4: data.nh4 ?? null,
    ph: data.ph ?? null,
    feeding: data.feeding ?? null,
    notEmptyFeeding: data.notEmptyFeeding ?? null,
    mortality: data.mortality ?? null,
    seeding: data.seeding ?? null,
    catch: data.catch ?? null,
    moving: data.moving ?? null,
    fishWeight: data.fishWeight ?? null,
    sale: data.sale ? data.sale : defaultSale,
    degreeday: data.degreeday ?? null
  }
  metrics.status = getWorstStatus(
    Object.values(metrics).map(item => item?.status).filter(Boolean)
  )
  return Object.freeze(metrics)
}

export const makeSiteMetrics = ({
  tankIds,
  prevMetricsByTanks,
  prevSaleRecords,
  metricsByTanks,
  hasFishByTanks,
  saleRecords
}) => {
  const m = metricsByTanks
  const fa = hasFishByTanks
  const siteTanksWithFish = tankIds.filter(id => fa[id])
  const fishChanges = siteTanksWithFish.map(id => m[id].fishChange)
  const fishAmount = sumBy(fishChanges, 'fishAmount')
  const fishBiomass = sumBy(fishChanges, 'fishBiomass')
  const fishWeight = fishAmount > 0 ? fishBiomass / fishAmount : 0
  const prevFishChanges = tankIds
    .filter(id => {
      const f = prevMetricsByTanks[id]?.fishChange
      return f && f.fishAmount > 0 && f.fishBiomass > 0
    })
    .map(id => prevMetricsByTanks[id].fishChange)
  const prevFishAmount = sumBy(prevFishChanges, 'fishAmount')
  const prevFishBiomass = sumBy(prevFishChanges, 'fishBiomass')
  const prevFishWeight = prevFishAmount > 0
    ? prevFishBiomass / prevFishAmount
    : 0
  const fishAmountDelta = fishAmount - prevFishAmount
  const fishBiomassDelta = fishBiomass - prevFishBiomass
  const fishWeightDelta = fishWeight - prevFishWeight
  const catchForSale = saleRecords.reduce((acc, record) => {
    acc.amount += record.amount
    acc.biomass += record.biomass
    return acc
  }, {
    amount: 0,
    biomass: 0
  })
  const prevCatchForSale = prevSaleRecords.reduce((acc, record) => {
    acc.amount += record.amount
    acc.biomass += record.biomass
    return acc
  }, {
    amount: 0,
    biomass: 0
  })
  const saleAmountDelta = catchForSale.amount - prevCatchForSale.amount
  const saleBiomassDelta = catchForSale.biomass - prevCatchForSale.biomass
  const temperatureList = tankIds.map(id => m[id].temperature).filter(Boolean)
  const oxygenList = tankIds.map(id => m[id].oxygen).filter(Boolean)
  const nitrogenList = tankIds.map(id => m[id].nitrogen).filter(Boolean)
  const no2List = tankIds.map(id => m[id].no2).filter(Boolean)
  const no3List = tankIds.map(id => m[id].no3).filter(Boolean)
  const nh4List = tankIds.map(id => m[id].nh4).filter(Boolean)
  const phList = tankIds.map(id => m[id].ph).filter(Boolean)
  const statuses = tankIds.map(id => metricsByTanks[id]?.status).filter(Boolean)
  return Object.freeze({
    tankIds,
    status: getWorstStatus(statuses),
    tanksCount: tankIds.length,
    fishAmount,
    fishBiomass,
    fishWeight,
    fishAmountDelta,
    fishBiomassDelta,
    fishWeightDelta,
    saleAmount: catchForSale.amount,
    saleBiomass: catchForSale.biomass,
    saleAmountDelta,
    saleBiomassDelta,
    avgTemperature: meanBy(temperatureList, v => v?.indicator ?? 0),
    avgOxygen: meanBy(oxygenList, v => v?.indicator ?? 0),
    avgNitrogen: meanBy(nitrogenList, v => v?.indicator ?? 0),
    avgNo2: meanBy(no2List, v => v?.indicator ?? 0),
    avgNo3: meanBy(no3List, v => v?.indicator ?? 0),
    avgNh4: meanBy(nh4List, v => v?.indicator ?? 0),
    avgPh: meanBy(phList, v => v?.indicator ?? 0),
    lastTemperatureTimestamp: getLastByTime(temperatureList)?.timestamp ?? null,
    lastOxygenTimestamp: getLastByTime(oxygenList)?.timestamp ?? null,
    lastNitrogenTimestamp: getLastByTime(nitrogenList)?.timestamp ?? null,
    lastNo2Timestamp: getLastByTime(no2List)?.timestamp ?? null,
    lastNo3Timestamp: getLastByTime(no3List)?.timestamp ?? null,
    lastNh4Timestamp: getLastByTime(nh4List)?.timestamp ?? null,
    lastPhTimestamp: getLastByTime(phList)?.timestamp ?? null,
    lastFeeding: getLastByTime(tankIds.map(id => m[id].feeding)),
    lastMortality: getLastByTime(tankIds.map(id => m[id].mortality))
  })
}

function getLastByTime (list) {
  const copy = list.filter(Boolean)
  copy.sort((a, b) => parseDate(a.timestamp) - parseDate(b.timestamp))
  return copy[copy.length - 1]
}

export function getStatusBoundaries (thresholds) {
  if (!Array.isArray(thresholds)) {
    return null
  }
  const [a, b] = thresholds
  const hasA = typeof a === 'number'
  const hasB = typeof b === 'number'
  if (hasA && hasB) {
    return {
      success: [a, b],
      warning: [a * 0.9, b * 1.1],
      danger: [0, Infinity]
    }
  }
  return null
}
