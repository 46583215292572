<template>
  <section class="shipping-card">
    <div class="card-header">
      <div class="card-header__handles">
        <el-tooltip :open-delay="500" placement="top" content="детали">
          <span :class="collapseIconClass" @click="toggleCollapse" />
        </el-tooltip>
        <el-tooltip :open-delay="500" placement="top" :content="statusText">
          <span :class="statusIconClass" />
        </el-tooltip>
        <el-tooltip
          v-if="hasWarnings"
          :open-delay="500"
          placement="top"
          :content="shipping.hasCriticalWarnings
            ? 'есть значительные отклонения от нормы'
            : 'есть отклонения от нормы'"
        >
          <span :class="warningIconClass" />
        </el-tooltip>
      </div>

      <div class="card-header__title">
        <span>
          {{ formatTimestamp(shipping.startTimestamp) }}
        </span>
        <template v-if="shipping.finishTimestamp">
          <span class="separator">–</span>
          <span>
            {{ formatTimestamp(shipping.finishTimestamp) }}
          </span>
        </template>

        <div>число баков: {{ shipping.tanksCount }}</div>
      </div>
    </div>

    <div v-if="!isCollapsed">
      <shipping-details :shipping-id="shipping.id" />
    </div>
  </section>
</template>

<script>
import ShippingDetails from '@/modules/iot/components/ShippingDetails'
import { formatTimestamp, parseISO as parseISODate } from '@/utils/date'

export default {

  components: {
    ShippingDetails
  },
  props: {
    shipping: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isCollapsed: true,
      isLoading: false,
      error: null,
      details: null
    }
  },

  computed: {
    collapseIconClass () {
      return this.isCollapsed ? 'mdi mdi-triangle-outline closed' : 'mdi mdi-triangle opened'
    },
    statusIconClass () {
      if (this.status.isShippingNow) {
        return 'mdi mdi-circle shipping--now'
      }
      if (this.status.isShippingPlanned) {
        return 'mdi mdi-circle shipping--planned'
      }
      if (this.status.isShippingFinished) { return 'mdi mdi-circle-outline shipping--finished' }
      return ''
    },
    statusText () {
      if (this.status.isShippingNow) {
        return 'в пути'
      }
      if (this.status.isShippingPlanned) {
        return 'в планах'
      }
      if (this.status.isShippingFinished) {
        return 'перевозка завершена'
      }
      return ''
    },
    status () {
      const now = Date.now()

      const { startTimestamp, finishTimestamp } = this.shipping
      const startDate = startTimestamp ? parseISODate(startTimestamp) : null
      const finishDate = finishTimestamp ? parseISODate(finishTimestamp) : null

      return {
        isShippingNow: startDate < now && !finishDate,
        isShippingPlanned: startDate > now,
        isShippingFinished: startDate && finishDate
      }
    },
    warningIconClass () {
      const iconClass = 'mdi mdi-alert-outline'
      return this.shipping.hasCriticalWarnings ? iconClass + ' danger' : iconClass + ' warning'
    },
    hasWarnings () {
      return this.shipping.hasCriticalWarnings || this.shipping.hasWarnings
    }
  },

  methods: {
    formatTimestamp,
    toggleCollapse () {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
</script>

<style lang="scss">
.shipping-card {
  .card-header {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    gap: 1em;
    &__handles {
      width: 100px;
      .mdi {
        font-size: 24px;
        padding: 0 4px;
        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, .05);
          border-radius: .25em;
        }
        &.closed::before {
          transform: rotate(90deg);
        }
        &.opened::before {
          transform: rotate(180deg);
        }
        &.warning {
          color: var(--color-warning-500);
        }
        &.danger {
          color: var(--color-danger-500);
        }
        &.shipping--now {
          color: var(--color-success-500);
        }
        &.shipping--planned {
          color: var(--color-warning-500);
        }
        &.shipping--finished {
          color: #000;
        }
      }
    }
    &__title {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      font-weight: 500;
      font-size: 16px;
      .separator {
        margin: 0;
      }
    }
  }
}
</style>
