import request from '@/utils/request'

export function fetchShippings () {
  return request({
    url: '/api/iot/shipping',
    method: 'get'
  })
}

export function fetchShippingDetails (id) {
  return request({
    url: `/api/iot/shipping/${id}/details`,
    method: 'get'
  })
}
